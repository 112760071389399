// @ts-strict-ignore
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { charcoal05, primary, secondary } from '../../utils/colors';
import Logo from '../../assets/icons/logoFull.svg';
import Triangle from '../../assets/icons/triangle.svg';
import Admin from '../../assets/icons/admin.svg';
import Target from '../../assets/icons/target.svg';
import Goggles from '../../assets/icons/goggles.svg';
import Help from '../../assets/icons/help.svg';
import TutorialVideo from '../../assets/icons/tutorial-video.svg';
import LightbulbIcon from '../../assets/icons/lightbulb.svg';
import Edit from '../../assets/icons/edit.svg';
import Logout from '../../assets/icons/logout.svg';
import Settings from '../../assets/icons/settings.svg';
import ToolkitIcon from '../../assets/icons/module-toolkit.svg';
import { useSelector, useDispatch } from 'react-redux';
import { server } from '../../../config-public';
import { config } from '../../../config';

import { Link, useLocation } from 'react-router-dom';
import { getAll as getClasses, resetGet, resetGetError } from '../../redux/actions/class';
import { signout as signoutAction } from '../../redux/actions/user';
import { NavLink } from './navLink';
import { InstitutionUserRole, UserRole } from '../../../types/models';
import { classDisplayName } from '../../utils/class';
import { RecentClass } from '../../pages/classListPage';
import { Alert, ExtraAlertText } from '../Alert';
import { Button } from '../button';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { Text } from '../../components/text';
import { Spinner } from '../Spinner';

interface Props {
  currentClassId?: number;
}

const LOCAL_STORAGE_IS_HIDDEN_KEY = 'isLeftNavHidden';
const HIDE_MENU_SCREEN_WIDTH = 1300;

const startHidden = () => {
  if (localStorage.getItem(LOCAL_STORAGE_IS_HIDDEN_KEY) !== null) {
    return localStorage.getItem(LOCAL_STORAGE_IS_HIDDEN_KEY) === 'true';
  } else {
    return window.innerWidth < HIDE_MENU_SCREEN_WIDTH;
  }
};

export const LeftNav: React.FC<Props> = ({ currentClassId }) => {
  const classes = useSelector((state) => state.class.classes);
  const fetchingClasses = useSelector((state) => state.class.fetchingClasses);
  const errorMessage = useSelector((state) => state.class.errorMessage);
  const hasGetError = useSelector((state) => state.class.hasGetError);
  const hasShownGetError = useSelector((state) => state.class.hasShownGetError);

  const user = useSelector((state) => state.user);

  const query = new URLSearchParams();
  query.append('first_name', user.firstName);
  query.append('last_name', user.lastName);
  if (user.emails.length > 0) query.append('email', user.emails[0]);
  query.append('page_url', document.location.href);
  query.append(
    'user_id',
    (server == 'app.prismsvr.com' ? 'admin.prismsvr.com' : 'admin-test.prismsvr.com') + '/admin/prisms/user/' + user.id,
  );

  const admin = user.roles.includes(UserRole.admin);
  // UserRole.teacher is deprecated in favor of InstitutionUserRole.teacher
  const teacher =
    user.roles.includes(UserRole.teacher) ||
    user.institutionRoles.some((ir) => ir.roles.includes(InstitutionUserRole.teacher));
  const institutionAdmin = user.institutionRoles.some((ir) => ir.roles.includes(InstitutionUserRole.institutionAdmin));
  const location = useLocation();
  const dispatch = useDispatch();
  const signout = () => dispatch(signoutAction());
  const RetryGetButton = styled(Button)({
    width: 'calc(100%-1rem)',
    margin: '0.5rem 0.5rem 0',
    cursor: fetchingClasses ? 'not-allowed' : 'pointer',
  });
  const [isHidden, setIsHidden] = React.useState(startHidden());
  const [showHamburgerTooltip, setshowHamburgerTooltip] = React.useState(false);

  const setIsHiddenAndPersist = (hide: boolean) => {
    if (hide != isHidden) {
      setIsHidden(hide);
      localStorage.setItem(LOCAL_STORAGE_IS_HIDDEN_KEY, hide ? 'true' : 'false');
      if (window.innerWidth < HIDE_MENU_SCREEN_WIDTH && hide) {
        setshowHamburgerTooltip(true);
        setTimeout(() => {
          setshowHamburgerTooltip(false);
        }, 3000);
      } else {
        setshowHamburgerTooltip(false);
      }
    }
  };

  useEffect(() => {
    // Handle initial hidden state
    if (localStorage.getItem(LOCAL_STORAGE_IS_HIDDEN_KEY) === null) {
      setIsHiddenAndPersist(startHidden());
    } else {
      setIsHiddenAndPersist(localStorage.getItem(LOCAL_STORAGE_IS_HIDDEN_KEY) === 'true' ? true : startHidden());
    }
  }, []);

  useEffect(() => {
    // Handle auto-hiding on navigation to certain pages (when small screen)
    const autoHideEndingList = [`/progress`, `/live`, `/summary`];
    autoHideEndingList.forEach((loc) => {
      if (location.pathname.endsWith(loc)) {
        if (window.innerWidth < HIDE_MENU_SCREEN_WIDTH && !isHidden) {
          setIsHiddenAndPersist(true);
        }
      }
    });
  }, [location]);

  const recentClass = RecentClass();

  /*
   * - The Account Settings page should be hidden for
   *   external logins.
   * - It hasn't seen any attention since June 2021:  I have
   *   no idea how well it functions.
   * - However, it is known that the handling of the user login
   *   name, user emails, and password updates are broken.
   *   (as of January 2023)
   */
  const accountSettings = false;

  return (
    <>
      <HideMenu>
        <MenuCheckBox
          id="openSidebarMenu"
          name="openSidebarMenu"
          type="checkbox"
          checked={isHidden}
          onChange={(e) => {
            setIsHiddenAndPersist(e.target.checked);
          }}
        />
        <Tooltip anchorSelect="#hamburger" isOpen={showHamburgerTooltip} place="right">
          <Text variant="p">To re-open the menu click this icon.</Text>
        </Tooltip>
        <SidebarIconToggle id="hamburger" htmlFor="openSidebarMenu" data-cy="left-nav-hamburger">
          <TopBread />
          <Meat />
          <BottomBread />
        </SidebarIconToggle>
      </HideMenu>
      <Container
        style={{
          marginLeft: isHidden ? '-20rem' : '0',
        }}
      >
        <LogoLink to="/">
          <StyledLogo />
        </LogoLink>
        {hasShownGetError && (
          <StyledAlert severity="error" title="Error loading classes">
            <RetryGetButton
              onClick={() => {
                dispatch(resetGetError());
                dispatch(getClasses());
              }}
              disabled={fetchingClasses}
            >
              {fetchingClasses ? <Spinner size="1rem" /> : 'Retry'}
            </RetryGetButton>
          </StyledAlert>
        )}
        <TopBottom>
          <Classes>
            {(admin || institutionAdmin) && (
              <>
                <NavLink
                  to="/admin/analytics"
                  icon={<Admin />}
                  label="Administrator Analytics"
                  active={location.pathname.startsWith('/admin/analytics')}
                />
                <NavLink
                  to="/admin/classes"
                  icon={<Admin />}
                  label="All Classes"
                  active={location.pathname.startsWith('/admin/classes')}
                />
                <NavLink
                  to="/admin/licenses"
                  icon={<Admin />}
                  label="License Management"
                  active={location.pathname.startsWith('/admin/licenses')}
                />
              </>
            )}
            {teacher && (
              <NavLink
                to="/class-list"
                icon={<Triangle />}
                label="My Classes"
                active={location.pathname.startsWith('/class-list')}
              />
            )}
            <NavLink
              to="/toolkits"
              icon={<StyledToolkitIcon />}
              label="Toolkits"
              active={location.pathname.startsWith('/toolkits')}
            />
            <NavLink
              to="/device-access"
              icon={<StyledGoggles />}
              label="Teacher Headset Login"
              active={location.pathname.startsWith('/device-access')}
            />
            {classes
              .filter((c) => c.teachers.some((u) => u.id == user.id) && recentClass(c))
              .map((c) => (
                <NavLink
                  key={'nav-class-' + c.id}
                  to={`/class/${c.id}`}
                  icon={<Target />}
                  label={classDisplayName(c, { section: true })}
                  active={c.id === currentClassId}
                />
              ))}
          </Classes>
          <LinkContainer>
            {teacher && (
              <NavLink
                to="/tutorial-video"
                icon={<TutorialVideo />}
                label="Dashboard Overview"
                active={location.pathname.startsWith('/tutorial-video')}
              />
            )}
            <NavLink
              onClick={() => {
                window.open(
                  'https://prismsvr-20007353.hs-sites.com/knowledge-base/getting-started-with-prisms-in-the-classroom#quick-tips',
                );
              }}
              icon={<LightbulbIcon />}
              label="Quick Tips"
            />
            <NavLink
              onClick={() => {
                window.open('https://prismsvr-20007353.hs-sites.com/knowledge-base');
              }}
              icon={<Help />}
              label="Help & Resources"
            />
            {admin && (
              <NavLink
                to="/admin/lti-platform"
                icon={<StyledSettings />}
                label="LTI Platforms"
                active={location.pathname.startsWith('/admin/lti-platform')}
              />
            )}
            {accountSettings && (
              <NavLink
                to="/account-settings"
                icon={<StyledEdit />}
                label="Account Settings"
                active={location.pathname.startsWith('/account-settings')}
              />
            )}
            <NavLink onClick={signout} icon={<StyledLogout />} label="Logout" />
          </LinkContainer>
        </TopBottom>
        <Alert
          isOpen={hasGetError && !hasShownGetError && !fetchingClasses}
          closeAlert={() => {
            dispatch(resetGet());
          }}
          severity="error"
          title="Error Retrieving Class Data"
          blocking={true}
        >
          {'Class data could not be retrieved.  This could be due to a loss of network connection.  Please send a screenshot to ' +
            config.supportEmail.staff +
            '.'}
          {errorMessage && <ExtraAlertText>Error message: {errorMessage}</ExtraAlertText>}
        </Alert>
      </Container>
    </>
  );
};

const startMargin = startHidden() ? '-20rem' : '0';

const Container = styled.div({
  height: '100vh',
  width: '20rem',
  backgroundColor: charcoal05,
  display: 'flex',
  flex: '0 1 auto',
  marginTop: '0',
  marginBottom: '0',
  marginLeft: startMargin,
  flexDirection: 'column',
  transition: 'margin 0.3s ease-in-out',
});

const StyledLogo = styled(Logo)({
  margin: '0 auto 0',
  paddingTop: '1.5rem',
  display: 'block',
  color: primary,
  ':hover': {
    color: secondary,
  },
});

const LogoLink = styled(Link)({
  margin: '0 auto 3rem',
  display: 'block',
});

const StyledSettings = styled(Settings)({
  marginRight: '0.1rem',
  marginLeft: '0.1rem',
});

const StyledGoggles = styled(Goggles)({
  width: '25px',
  marginLeft: '-0.1rem',
  marginRight: '0rem',
});

const StyledToolkitIcon = styled(ToolkitIcon)({
  width: '25px',
  height: '25px',
  marginLeft: '-0.1rem',
  marginRight: '0rem',
});

const StyledEdit = styled(Edit)({
  marginRight: '0.3rem',
});

const StyledLogout = styled(Logout)({
  marginRight: '0.2rem',
});

const TopBottom = styled.div({
  flex: 1,
  minHeight: '0',
  display: 'flex',
  paddingBottom: '1rem',
  overflow: 'scroll',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const Classes = styled.div({
  marginBottom: '3rem',
});

const LinkContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
});

const StyledAlert = styled(Alert)({
  margin: ' 0 0.5rem 0',
});

const HideMenu = styled('div')({
  position: 'absolute',
  top: '0.5rem',
  left: '0.5rem',
});

const SidebarIconToggle = styled('label')({
  boxSizing: 'border-box',
  cursor: 'pointer',
  position: 'absolute',
  zIndex: 99,
  top: '1.5rem',
  left: '1.5rem',
  height: '22px',
  width: '22px',
  background: '#fff',
  boxShadow: '0 0 20px 20px #fff',
});

const MenuCheckBox = styled('input')({
  boxSizing: 'border-box',
  display: 'none',
});

const BurgerLayer = styled('div')({
  boxSizing: 'border-box',
  position: 'relative',
  float: 'left',
  height: '3px',
  width: '100%',
  backgroundColor: primary,
});

const TopBread = styled(BurgerLayer)({
  marginBottom: '3px',
});

const Meat = styled(BurgerLayer)({});

const BottomBread = styled(BurgerLayer)({
  marginTop: '3px',
});
