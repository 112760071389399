import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sortBy } from 'lodash';

import { Header } from '../../components/header';
import { LeftNav } from '../../components/leftNav';
import { PageContainer } from '../../components/pageContainer';
import { Alert, ExtraAlertText } from '../../components/Alert';
import { Text } from '../../components/text';
import { ActionBar } from '../../components/styles';
import { primary, gray } from '../../utils/colors';
import { StyledButton } from '../../components/StyledComponents';

import { InstitutionRow } from './InstitutionRow';
import { LicenseUsage } from './LicenseUsage';
import { ClassRow } from './ClassRow';
import { displayName, institutionSortOrder } from '../../utils/institution';
import { classSortOrder } from '../../utils/class';
import { current } from './LicenseUsage';
import { Contract } from '../../../types/routes/accountManagement';
import {
  getInstitutionAction,
  getInstitutionChildrenAction,
  getInstitutionClassesWithUsersAction,
  getInstitutionContractsAction,
} from '../../redux/actions/accountManagement';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../../config';
import { URL_QUERY_ARRAY_JOINER } from '../../utils/query';
import { Spinner } from '../../components/Spinner';

export const ManageInstitution: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const timing = query.get('timing') ? true : false;
  const dispatch = useDispatch();
  const institutionId = params.institutionId ? parseInt(params.institutionId) : -1;
  const institution = useSelector((s) => s.accountManagement.institutions[institutionId]);
  const institutions = useSelector((state) => state.accountManagement.institutions);
  const children = institution ? institution.children.map((id) => institutions[id]).filter((x) => x) : [];
  children.sort(institutionSortOrder);
  const allClasses = useSelector((state) => state.accountManagement.classesWithUsers);
  const classes = institution ? institution.classes.map((id) => allClasses[id]).filter((x) => x) : [];
  classes.sort(classSortOrder);

  const institutionContracts = useSelector((s) => s.accountManagement.institutionContracts[institutionId]);

  const hasError = useSelector((state) => state.accountManagement.hasError);
  const errorMessage = useSelector((state) => state.accountManagement.errorMessage);

  const [filter, setFilter] = useState<Contract[]>([]);

  useEffect(() => {
    const contracts = query.get('contractIds') ?? '';
    const urlContractIds = contracts.split(URL_QUERY_ARRAY_JOINER).map((y: string) => parseInt(y));
    if (institutionContracts?.length) {
      setFilter([...institutionContracts.filter((ic) => urlContractIds.includes(ic.id))]);
    }
    if (!urlContractIds.length && institutionContracts && filter.length == 0) {
      setFilter(institutionContracts.filter(current));
    }
  }, [institutionContracts]);

  useEffect(() => {
    if (!institution) {
      if (debugPrint) console.log(`--- dispatch root institution ${institutionId}`);
      dispatch(getInstitutionAction(institutionId));
    } else {
      for (const childId of institution.children)
        if (!(childId in institutions)) {
          if (debugPrint) console.log(`--- dispatch child ${childId}`);
          dispatch(getInstitutionChildrenAction({ id: institutionId, timing }));
          break;
        }
      for (const c of institution.classes)
        if (!(c in allClasses)) {
          if (debugPrint) console.log(`--- dispatch class ${c}`);
          dispatch(getInstitutionClassesWithUsersAction({ id: institutionId, timing }));
          break;
        }
    }
    if (!institutionContracts) {
      if (debugPrint) console.log(`--- dispatch contracts for ${institutionId}`);
      dispatch(getInstitutionContractsAction(institutionId));
    }
  }, [institution]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const selectedContractIds = filter.map((c) => c.id.toString());
    if (institutionContracts?.length) {
      query.delete('contractIds');
      if (selectedContractIds.length) query.append('contractIds', selectedContractIds.join(URL_QUERY_ARRAY_JOINER));
      navigate(
        {
          pathname: window.location.pathname,
          search: query.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [filter]);

  if (institution) {
    return (
      <PageContainer header={<Header />} leftNav={<LeftNav />}>
        <ActionBar>
          <Text variant="lg2" color={primary}>
            {displayName(institution)}
          </Text>
          <ButtonMailto label="Purchase More Licenses" mailto="mailto: support@prismsvr.com" />
        </ActionBar>
        {institutionContracts && institutionContracts.length > 0 ? (
          sortBy(institutionContracts, [(c) => (current(c) ? 0 : 1), (x) => -x.contractStartDate, (c) => -c.id]).map(
            (c) => (
              <LicenseUsage
                key={`contract-${c.id}`}
                contract={c}
                parentId={institution.id}
                filter={filter}
                setFilter={setFilter}
              />
            ),
          )
        ) : (
          <Alert severity="warning" title="No Contracts" />
        )}
        {hasError && (
          <Alert severity="error" title="Could not fetch schools, classes, or licenses">
            {'Data could not be retrieved.  This could be due to a loss of network connection.  Please send a screenshot to ' +
              config.supportEmail.staff +
              '.'}
            {errorMessage && <ExtraAlertText>Error message: {errorMessage}</ExtraAlertText>}
          </Alert>
        )}
        {children.map((child) => (
          <InstitutionRow institution={child} key={`child-${child.id}`} filter={filter} />
        ))}
        {institution && institution.children.length > children.length && (
          <Text variant="nav" center color={gray}>
            Getting institution &hellip;
          </Text>
        )}
        {classes.map((theClass) => (
          <ClassRow classItem={theClass} key={`class-${theClass.id}`} filter={filter} />
        ))}
      </PageContainer>
    );
  } else {
    return <Spinner label="Getting institution ..." center />;
  }
};

const ButtonMailto = ({ mailto, label }: { mailto: any; label: any }) => {
  return (
    <StyledButton
      onClick={(e) => {
        window.location = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </StyledButton>
  );
};
