// @ts-strict-ignore
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SubjectSummaryReport, SummaryReportLeafData } from './subjectSummaryReport';
import { Dictionary } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { SubjectTree } from '../../components/SubjectTree';
import { getStudentUsage } from '../../redux/actions/class';
import { ModuleEventWithUserClass } from '../../../types/models';
import { Class as ClassWithStudents } from '../../../types/routes/class';
import { StatsWrapperClassroom } from '../../components/StatsWrapperClassroom';
import Summary from '../../assets/icons/summary.svg';
import { Spinner } from '../../components/Spinner';

interface Props {
  currentClass: ClassWithStudents;
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
  lastEventByStudent: Dictionary<ModuleEventWithUserClass>;
}

export const ClassSummaryPage: React.FC = () => {
  const params = useParams();
  return (
    <StatsWrapperClassroom
      match={params}
      ChildComponent={ClassSummaryChild}
      pageTitle="Class Summary Report"
      classOnlyData={true}
      Icon={Summary}
    />
  );
};

const ClassSummaryChild = ({ currentClass, eventsByStudent, lastEventByStudent }: Props) => {
  const dispatch = useDispatch();
  const modules = useSelector((state) => state.module.definitions);
  const studentUsage = useSelector((state) => state.class.studentUsage);
  const fetchingStudentUsage = useSelector((state) => state.class.fetchingStudentUsage);

  useEffect(() => {
    dispatch(getStudentUsage(currentClass.id));
  }, [currentClass]);

  const leafDataModuleList = new Set<string>();
  for (const s of Object.values(studentUsage.students)) {
    for (const moduleId of Object.keys(s.modules)) {
      leafDataModuleList.add(moduleId);
    }
  }

  if (!currentClass || fetchingStudentUsage || Object.keys(modules).length == 0) {
    return <Spinner label="Fetching data ..." center />;
  } else {
    return (
      <SubjectTree<SummaryReportLeafData>
        leafData={{ eventsByStudent, lastEventByStudent }}
        leafDataModuleList={leafDataModuleList}
        LeafComponent={SubjectSummaryReport}
      />
    );
  }
};
