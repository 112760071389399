// @ts-strict-ignore
import React, { MutableRefObject } from 'react';
import { TaskDefinition } from '../../../types/routes/module';
import styled from '@emotion/styled';
import { primary, charcoal15, charcoal05, secondary, black } from '../../utils/colors';
import { map } from 'lodash';
import { Text } from '../../components/text';
import { TimelineTask } from '../../components/TaskModal';
import { TaskState } from './types';

interface Props {
  moduleId: string;
  tasks: TaskDefinition[];
  moduleState: {
    [taskId: string]: TaskState;
  };
  studentId?: number;
  activeTask?: TaskDefinition;
  onTaskClick?: (task: TaskDefinition) => void;
  taskRef?: MutableRefObject<any>;
}

export const Timeline = ({
  moduleId,
  tasks,
  moduleState, // There should be an entry for each task
  studentId,
  activeTask,
  onTaskClick,
  taskRef,
  ...rest
}: Props) => {
  // console.log('*** Timline with state', moduleState);
  return (
    <Container {...rest}>
      {map(tasks, (task: TaskDefinition, index: number) => {
        // Bvds:  I couldn't figure out how to move the line between tasks
        // to the left of the task.  This is a work-around to get the
        // line color right.
        const nextTask: TaskDefinition | null = index + 1 < tasks.length ? tasks[index + 1] : null;
        if (!(task.id in moduleState))
          console.warn(`**** module ${moduleId} moduleState ${Object.keys(moduleState)} missing task ${task.id}`);
        else if (nextTask && !(nextTask.id in moduleState))
          console.warn(`**** module ${moduleId} moduleState ${Object.keys(moduleState)} missing task ${nextTask.id}`);
        const nextState = nextTask ? moduleState[nextTask.id] : null;
        const nextStarted: boolean | null = nextState ? moduleState && 2 * nextState.started >= nextState.count : null;

        const active = activeTask?.id === task.id;
        const state = moduleState[task.id];
        const started = state ? 2 * state.started >= state.count : false;
        const finished = state ? 2 * state.finished >= state.count : false;
        return (
          <TaskContainer ref={(started && !finished && taskRef) || null} key={index} next={nextTask ? true : false}>
            {nextTask && (
              <>
                <Line />
                <InProgressLine filled={finished && !nextStarted} />
                <FinishedLine filled={nextStarted} />
              </>
            )}
            <TaskNumber bold={active} variant="sm" color={black}>
              Task {index + 1}
            </TaskNumber>
            <TimelineTask
              moduleId={moduleId}
              key={task.id}
              taskIndex={index}
              task={task}
              state={state}
              onClick={onTaskClick}
              active={active}
              wholeClass={!studentId}
            />
            <TaskName bold={active} variant="sm" color={black}>
              {task.name}
            </TaskName>
          </TaskContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.div({
  /* Distance between left side of the first element
   * and the edge of the scrolling box.
   */
  paddingLeft: '2.75rem',
  boxSizing: 'border-box',
  display: 'flex',
  overflow: 'auto',
});

const TaskContainer = styled.div<{ next: boolean }>(
  {
    position: 'relative',
    flexShrink: 0,
    // Room for the scrollbar
    paddingBottom: '1em',
  },
  ({ next }) => ({
    // Padding on the right of the last task.
    width: next ? '8rem' : '4.75rem',
  }),
);

const Line = styled.div({
  position: 'absolute',
  width: '100%',
  height: '3px',
  backgroundColor: charcoal15,
  boxShadow: `0px 3px 3px ${charcoal05}`,
  top: '2.25rem',
});

const FilledLine = styled(Line)<{ filled: boolean }>(
  {
    transition: 'width .3s',
  },
  ({ filled }) => ({
    width: filled ? '100%' : 0,
  }),
);

const InProgressLine = styled(FilledLine)({
  backgroundColor: secondary,
});

const FinishedLine = styled(FilledLine)({
  backgroundColor: primary,
});

const TaskNumber = styled(Text)({
  marginBottom: '.5rem',
  position: 'relative',
});

const TaskName = styled(Text)({
  marginTop: '.5rem',
  marginLeft: '-2rem',
  position: 'relative',
  width: '6rem',
  textAlign: 'center',
});
