export const primary = '#d92073';
export const primaryDesaturated = '#af4b7a';
export const secondary = '#fb8047';
export const secondaryBright = '#ffa333';
export const secondaryDesaturated = '#d98c68';
export const white = '#ffffff';
export const black = '#000000';
export const grayBackground = '#FCFCFC';

export const darkgray40 = '#666666';
export const darkgray30 = '#b3b3b3';

export const charcoal15 = '#E4E4E4';
export const charcoal05 = '#F4F4F4';
export const green = '#00DD7A';
export const blue = '#0078DF';
export const red = '#FF4255';
export const yellow = '#ffd966';
export const gray = '#969696';
export const pink = '#F5DEE9';

export const grayCC = '#CCCCCC';
