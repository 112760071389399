// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModuleEventWithUserClass, UserStateName } from '../../../types/models';
import { UserState } from '../../../types/routes/class';
import {
  PauseNowRecord,
  PauseNow,
  PauseAtTasksRecord,
  PauseAtTasks,
  ModulePartRecord,
  ModulePart,
  ScreencastRecord,
  Screencast,
} from '../../../types/user-state';

import PlayIcon from '../../assets/icons/play.svg';
import PauseIcon from '../../assets/icons/pause.svg';
import LaunchIcon from '../../assets/icons/launch.svg';
import PauseAtTaskIcon from '../../assets/icons/pause-at-task.svg';
import LanguageIcon from '../../assets/icons/language.svg';
import UnlockHintIcon from '../../assets/icons/unlock-hint.svg';
import MessageIcon from '../../assets/icons/message.svg';
import ScreencastIcon from '../../assets/icons/Chromecast_cast_button_icon.svg';

import { getDefinitions } from '../../redux/actions/module';
import { pushOrInsertClassState } from '../../redux/actions/class';
import { ModuleFormat } from '../../utils/module';
import { formatName } from '../../utils/user';
import { getEpochTimeAfterHoursInSeconds } from '../../utils/time';
import { Text } from '../../components/text';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { ShowSetSelectedTask, SetSelectedTask } from '../../components/TaskSelector';

import {
  TIMEOUT,
  ConfigHeader,
  ActionHeader,
  ActionButton,
  IconPrimary,
  EXPIRATION_AFTER_HOURS,
} from './ClassroomButtons';
import {
  ErrorPrints,
  MessageModalState,
  SettingsModalState,
  StudentOverrides,
  StudentStatus,
  UserWithClassId,
  ScreencastStatus,
} from './types';
import { getEnabledFeaturesForStudent, updateStudentStatusOverrides } from './event-util';

interface Props {
  student: UserWithClassId;
  studentStatus?: StudentStatus;
  setSettingsModalState: (state: SettingsModalState) => void;
  setTaskSelectorState: (state: ShowSetSelectedTask) => void;
  errorList: ErrorPrints;
  setMessageModalState: React.Dispatch<React.SetStateAction<MessageModalState>>;
  lastEvent: ModuleEventWithUserClass;
}

enum ScreencastState {
  none = 'none',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}
interface ScreencastWindow {
  state: ScreencastState;
  message?: string;
  requestId?: number;
}

// Used for debug print
const listify = (x: { [key: string]: any }): React.JSX.Element => {
  if (x)
    return (
      <ul>
        <>
          {Object.entries(x).map(
            ([key, value]: [string, any], index): React.JSX.Element => (
              <li key={'listify-' + index}>
                <Text variant="nav">
                  {key}: {JSON.stringify(value)}
                </Text>
              </li>
            ),
          )}
        </>
      </ul>
    );
  else return <Text variant="nav">-</Text>;
};

export const StudentButtons = ({
  student,
  studentStatus,
  setMessageModalState,
  setSettingsModalState,
  setTaskSelectorState,
  errorList,
  lastEvent,
}: Props) => {
  const [errorPrints, setErrorPrints] = errorList;

  const moduleVersionDefinitions = useSelector((state) => state.module.versionDefinitions);
  const moduleDefinitions = useSelector((state) => state.module.definitions);
  const fetchingDefinitions = useSelector((state) => state.module.fetchingDefinitions);
  useEffect(() => {
    if (!fetchingDefinitions && Object.keys(moduleDefinitions.modules).length === 0) dispatch(getDefinitions());
  }, []);

  // State overrides for  this user
  const thisClassUserState = useSelector((state) => state.class.classState.find((cs) => cs.classId == student.classId));
  const classStateLoading = useSelector(
    (state) => state.class.fetchingClassState || state.class.fetchingEvents || state.class.postingClassState,
  );
  const [pauseNow, setPauseNow] = useState<boolean>(false);
  const [pauseTimer, setPauseTimer] = useState<number | null>(null);
  const [autoLaunch, setAutoLaunch] = useState<ModulePart | undefined>(undefined);
  const [pauseAtTasks, setPauseAtTasks] = useState<PauseAtTasks | undefined>(undefined);
  const [settingsOverridden, setSettingsOverridden] = useState<boolean>(false);
  const [autoLaunchOverridden, setAutoLaunchOverridden] = useState<boolean>(false);
  const [pauseAtTasksOverridden, setPauseAtTasksOverridden] = useState<boolean>(false);

  // Create student-wise redux state for the screencast window status
  const [screencastWindows, setScreencastWindows] = useState<{ [userId: number]: ScreencastWindow }>({});
  // student-wise setter
  const setScreencastWindow = (screencastWindow: ScreencastWindow) => {
    setScreencastWindows({ ...screencastWindows, [student.id]: screencastWindow });
  };
  const screencastWindow: ScreencastWindow =
    student.id in screencastWindows ? screencastWindows[student.id] : { state: ScreencastState.none };

  const tipId = 'message-' + student.id;

  // It might be good to consolidate with page-wise error printing?
  // This may be difficult for the class progress page modal
  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;
  const _timeout: number = debug ? 15 : TIMEOUT;

  const dispatch = useDispatch();

  // Establish what orchestration features to enable based on student's
  // VR version if they have recent activity.
  const studentFeatures = getEnabledFeaturesForStudent(studentStatus, moduleVersionDefinitions, moduleDefinitions);
  const launchIntoModuleEnabled = studentFeatures.includes('launch-into-module');
  const pauseAtTasksEnabled = studentFeatures.includes('pause-at-task');
  const pauseNowEnabled = studentFeatures.includes('pause-now');

  /*
   * BvdS:  this is copied from ClassroomStudentButtons.tsx
   * with modifications for this case ...
   */

  // Evaulate class states, setting pause, pauseTasks, & autoLaunch
  useEffect(() => {
    let _pauseNow: PauseNow | null | undefined = undefined;
    let _pauseAtTasks: PauseAtTasks | null | undefined = undefined;
    let _autoLaunch: ModulePart;
    let _screencastState: Screencast | null | undefined = undefined;
    const now = Date.now() / 1000;

    /*
     * in redux/reducers/class.ts, class-wise variables
     * are ordered before student-specific overrides.
     * Note that student-specific overrides may be null-valued.
     */
    if (thisClassUserState)
      for (const us of thisClassUserState.userStates) {
        // Ignore other students
        if (us.userId !== null && us.userId !== student.id) continue;
        try {
          // Student override expressed as JSON null
          if (us.name == UserStateName.pauseNow) {
            _pauseNow = us.value ? PauseNowRecord.check(us.value) : null;
          } else if (us.name == UserStateName.pauseAtTasks) {
            const possiblePauseAtTasks = us.value ? PauseAtTasksRecord.check(us.value) : null;
            if (possiblePauseAtTasks?.expiresAt && possiblePauseAtTasks.expiresAt < now) _pauseAtTasks = null;
            else _pauseAtTasks = possiblePauseAtTasks;
          } else if (us.name == UserStateName.launchIntoModule) {
            const possibleAutoLaunch = us.value ? ModulePartRecord.check(us.value) : null;
            if (possibleAutoLaunch?.expiresAt && possibleAutoLaunch.expiresAt < now) _autoLaunch = null;
            else _autoLaunch = possibleAutoLaunch;
          } else if (us.name == UserStateName.screencast) {
            const possibleScreencast = us.value ? ScreencastRecord.check(us.value) : null;
            _screencastState = possibleScreencast && possibleScreencast.expiresAt > now ? possibleScreencast : null;
          }
        } catch (error) {
          const ref = us.name;
          if (!(ref in errorPrints)) {
            const message = `${ref} invalid form ${JSON.stringify(us.value)}`;
            setErrorPrints({ ...errorPrints, [ref]: message });
          }
        }
      }
    if (pauseTimer !== null) window.clearTimeout(pauseTimer);
    if (_pauseNow) {
      // all instances are pause == true
      const isPause = now > _pauseNow.timestamp && now < _pauseNow.timestamp + _pauseNow.timeout;
      const timeout = _pauseNow.timeout + _pauseNow.timestamp - now;
      setPauseNow(isPause);
      if (isPause) {
        // Expecting milliseconds
        setPauseTimer(window.setTimeout(() => setPauseNow(false), timeout * 1000));
      } else setPauseTimer(null);
    } else {
      setPauseNow(false);
      setPauseTimer(null);
    }
    setPauseAtTasks(_pauseAtTasks);
    setAutoLaunch(_autoLaunch);
    // If screencasting has been turned off, the state variable becomes null
    // In that case, set the button state to "none"
    // Avoid race condition that may occur when loading: state is
    // still empty just after dispatching new request.
    if (
      !_screencastState &&
      screencastWindow.state != ScreencastState.none &&
      screencastWindow.state != ScreencastState.loading
    )
      setScreencastWindow({ state: ScreencastState.none });

    // highlight student setting overrides
    if (thisClassUserState && studentStatus && studentStatus.overrides) {
      const statusArray: StudentStatus[] = [];
      statusArray[student.id] = studentStatus;
      updateStudentStatusOverrides([thisClassUserState], statusArray);
      const updatedStudentStatus = statusArray[student.id];
      setSettingsOverridden(updatedStudentStatus.overrides.has(StudentOverrides.settings));
      setAutoLaunchOverridden(updatedStudentStatus.overrides.has(StudentOverrides.launch));
      setPauseAtTasksOverridden(updatedStudentStatus.overrides.has(StudentOverrides.pause));
    }

    /*
     * Ideally, one would test the URL to see if it is reachable.
     * However, that is prevented by CORS.
     */
    const screencast = studentStatus.screencast;
    if (screencast.status == ScreencastStatus.success) {
      const url = screencast.url;
      const requestId = screencast.requestId;
      if (url && requestId) {
        // Only open window if screencast is still turned on.
        if (
          screencastWindow.state == ScreencastState.loading &&
          screencastWindow.requestId &&
          requestId == screencastWindow.requestId
        ) {
          const w = window.open(url, 'screencast:' + student.id);
          if (debug)
            console.log(
              `screencast window open ${w ? 'success' : 'failed'} at ${url} for ${student.id}, requestId ${requestId}`,
            );
          if (w) {
            setScreencastWindow({ ...screencastWindow, state: ScreencastState.loaded });
          } else {
            const message = 'Unable to open a screencast window for ' + formatName(student);
            setScreencastWindow({ ...screencastWindow, state: ScreencastState.error, message });
          }
        } else if (debug)
          console.log(
            `screencast window open at ${url} ignored for ${
              student.id
            }, requestId ${requestId} previous ${JSON.stringify(screencastWindow)}`,
          );
      }
    } else if (screencast.status == ScreencastStatus.error && screencastWindow.state != ScreencastState.error) {
      setScreencastWindow({ ...screencastWindow, state: ScreencastState.error, message: screencast.message });
    }
  }, [student.id, thisClassUserState, studentStatus]);

  const playPauseClicked = () => {
    if (!classStateLoading) {
      const data: UserState[] = [
        {
          userId: student.id,
          name: UserStateName.pauseNow,
          // BvdS: since this is an override, it should be
          // set to null, rather than be removed from the state table.
          // On the VR side, null is sent in any case.
          value: pauseNow
            ? null
            : {
                timestamp: Math.floor(Date.now() / 1000),
                timeout: _timeout,
              },
        },
      ];
      dispatch(
        pushOrInsertClassState({
          classId: student.classId,
          push: true,
          undoOverrides: false,
          data,
        }),
      );
    }
  };

  const screencastClicked = () => {
    if (!classStateLoading) {
      const requestId = Math.floor(Date.now() / 1000);
      setScreencastWindow({ ...screencastWindow, state: ScreencastState.loading, requestId });

      // We don't turn screencasting off using the button.
      // The user must close the window.
      // The user can re-request screencasting.
      const data: UserState[] = [
        {
          userId: student.id,
          name: UserStateName.screencast,
          value: {
            options: {
              OPTION_RESOLUTION_LEVEL: 'OPTION_VALUE_RESOLUTION_HIGH_2K',
              OPTION_AUDIO_ENABLE: 'OPTION_VALUE_AUDIO_OFF',
            },
            expiresAt: getEpochTimeAfterHoursInSeconds(EXPIRATION_AFTER_HOURS),
            // Identifier for the screencast request message.
            requestId,
          },
        },
      ];
      dispatch(
        pushOrInsertClassState({
          classId: student.classId,
          push: true,
          undoOverrides: false,
          data,
        }),
      );
    }
  };

  const unlockHintClicked = () => {
    if (!classStateLoading) {
      const data: UserState[] = [
        {
          userId: student.id,
          name: UserStateName.updateObjectNow,
          value: {
            object: 'bottomOutHintButton',
            status: 'active',
            timestamp: Math.floor(Date.now() / 1000),
            timeout: _timeout,
          },
        },
      ];
      dispatch(
        pushOrInsertClassState({
          classId: student.classId,
          push: true,
          undoOverrides: false,
          data,
        }),
      );
    }
  };

  const pauseAtTaskClicked: SetSelectedTask = (task) => {
    debug && console.log('pauseAtTaskClicked');
    if (!classStateLoading) {
      const data: UserState[] = [
        {
          userId: student.id,
          name: UserStateName.pauseAtTasks,
          // Use null instead of unsetting variable so that override works.
          value: task
            ? {
                tasks: [task],
                timeout: _timeout,
                expiresAt: getEpochTimeAfterHoursInSeconds(EXPIRATION_AFTER_HOURS),
              }
            : null,
        },
      ];
      debug && console.log(data);
      dispatch(
        pushOrInsertClassState({
          classId: student.classId,
          push: true,
          undoOverrides: false,
          data,
        }),
      );
      setTaskSelectorState(null);
    }
  };

  const launchIntoTaskClicked: SetSelectedTask = (task) => {
    debug && console.log('launchIntoTaskClicked');
    if (!classStateLoading) {
      debug && console.log(task);
      const data: UserState[] = [
        {
          userId: student.id,
          name: UserStateName.launchIntoModule,
          // Use null instead of unsetting variable so that override works.
          value: task
            ? {
                ...task,
                expiresAt: getEpochTimeAfterHoursInSeconds(EXPIRATION_AFTER_HOURS),
              }
            : null,
        },
      ];
      debug && console.log(data);
      dispatch(
        pushOrInsertClassState({
          classId: student.classId,
          push: true,
          undoOverrides: false,
          data,
        }),
      );
      setTaskSelectorState(null);
    }
  };

  // update tooltip text
  const getLaunchIntoModuleToolTipText = (): string => {
    if (launchIntoModuleEnabled) {
      return autoLaunch === null
        ? 'Auto Launch disabled'
        : autoLaunch
        ? 'Auto Launch at ' +
          (autoLaunch.taskId
            ? moduleFormat.taskName(autoLaunch.moduleId, autoLaunch.taskId)
            : moduleFormat.partName(autoLaunch.moduleId, autoLaunch.part))
        : 'Enable auto-launch for ' + formatName(student);
    }
    return `This student is using a version of the App that does not support Launch at Task.`;
  };
  const getPauseAtTasksToolTipText = (): string => {
    if (pauseAtTasksEnabled) {
      return pauseAtTasks === null
        ? 'End-at-task disabled'
        : pauseAtTasks
        ? 'End at ' + moduleFormat.taskName(pauseAtTasks.tasks[0].moduleId, pauseAtTasks.tasks[0].taskId)
        : 'Enable end-at-task for ' + formatName(student);
    }
    return `This student is using a version of the App that does not support End at Task.`;
  };
  const getPauseNowToolTipText = (): string => {
    if (pauseNowEnabled) {
      return (pauseNow ? 'Resume' : 'Pause') + ' ' + formatName(student);
    }
    return `This student is using a version of the App that does not support Pause.`;
  };
  const getScreencastToolTipText = (): string => {
    if (studentStatus.screencast.available) {
      switch (screencastWindow.state) {
        case ScreencastState.none:
          return 'Screencast' + ' ' + formatName(student);
        case ScreencastState.loaded:
          return 'Screencasting' + ' ' + formatName(student);
        case ScreencastState.loading:
          return 'Screencast loading';
        case ScreencastState.error:
          return screencastWindow.message || 'Screencast error';
      }
    }
    return studentStatus.screencast.message || `Screencasting is not available.`;
  };

  const moduleFormat = new ModuleFormat(moduleDefinitions.modules);

  return (
    <>
      {debug && (
        <Text variant="nav">
          Timeout {_timeout}s {pauseNow || '-'} {pauseTimer || '-'}{' '}
        </Text>
      )}
      {setSettingsModalState && (
        <>
          <ConfigHeader>
            <ActionButton
              disabled={classStateLoading}
              style={{
                width: '1.5rem',
              }}
              data-cy="liveClassroom-set-language-student"
              id="set-language-student"
              onClick={() => {
                setSettingsModalState({ open: true, classIds: [student.classId], student });
              }}
            >
              <IconPrimary state={settingsOverridden && !classStateLoading}>
                <LanguageIcon />
              </IconPrimary>
            </ActionButton>
            <Tooltip anchorSelect="#set-language-student">
              <Text variant="nav">{'Set Language for ' + formatName(student)}</Text>
            </Tooltip>
            <ActionButton
              disabled={!launchIntoModuleEnabled || classStateLoading}
              id="auto-launch-student"
              onClick={() =>
                setTaskSelectorState({
                  showTasks: true,
                  setSelectedTask: launchIntoTaskClicked,
                  selectedTask: autoLaunch,
                  taskFilter: (t) => ('snapshot' in t ? t.snapshot : true),
                  firstTaskIsPart: true,
                })
              }
              data-cy="live-classroom-auto-launch-button-student"
            >
              <IconPrimary state={launchIntoModuleEnabled && autoLaunchOverridden && !classStateLoading}>
                <LaunchIcon />
              </IconPrimary>
            </ActionButton>
            <Tooltip anchorSelect="#auto-launch-student">
              <Text variant="nav">{getLaunchIntoModuleToolTipText()}</Text>
            </Tooltip>
            <ActionButton
              disabled={!pauseAtTasksEnabled || classStateLoading}
              id="pause-at-task-student"
              onClick={() =>
                setTaskSelectorState({
                  showTasks: true,
                  setSelectedTask: pauseAtTaskClicked,
                  selectedTask: pauseAtTasks && pauseAtTasks.tasks.length > 0 ? pauseAtTasks.tasks[0] : null,
                  firstTaskIsPart: false,
                })
              }
              data-cy="live-classroom-pause-at-task-button-student"
            >
              <IconPrimary state={pauseAtTasksEnabled && pauseAtTasksOverridden && !classStateLoading}>
                <PauseAtTaskIcon />
              </IconPrimary>
            </ActionButton>
            <Tooltip anchorSelect="#pause-at-task-student">
              <Text variant="nav">{getPauseAtTasksToolTipText()}</Text>
            </Tooltip>
          </ConfigHeader>
          <ActionHeader>
            <ActionButton
              id="play-pause-student"
              disabled={!pauseNowEnabled || classStateLoading}
              onClick={playPauseClicked}
              data-cy="live-classroom-play-pause-button-student"
            >
              {pauseNow ? (
                <IconPrimary state={pauseNowEnabled && !classStateLoading}>
                  <PlayIcon />
                </IconPrimary>
              ) : (
                <PauseIcon />
              )}
            </ActionButton>
            <Tooltip anchorSelect="#play-pause-student">
              <Text variant="nav">{getPauseNowToolTipText()}</Text>
            </Tooltip>
            <ActionButton
              id="screencast-student"
              disabled={
                !studentStatus.screencast.available ||
                screencastWindow.state == ScreencastState.loading ||
                classStateLoading
              }
              onClick={screencastClicked}
              data-cy="live-classroom-screencast-student"
            >
              <IconPrimary state={screencastWindow.state == ScreencastState.loaded}>
                <ScreencastIcon />
              </IconPrimary>
            </ActionButton>
            <Tooltip anchorSelect="#screencast-student">
              <Text variant="nav">{getScreencastToolTipText()}</Text>
            </Tooltip>
            {debug && (
              <>
                <ActionButton
                  id="unlock-hint-student"
                  onClick={unlockHintClicked}
                  data-cy="liveClassroom-unlock-hint-student"
                >
                  {' '}
                  <UnlockHintIcon />
                </ActionButton>
                <Tooltip anchorSelect="#unlock-hint-student">
                  <Text variant="nav">{'Unlock hint for ' + formatName(student)}</Text>
                </Tooltip>
              </>
            )}
            {setMessageModalState && (
              <>
                <ActionButton
                  data-cy="StudentInformation-message-student"
                  id={tipId}
                  onClick={() => {
                    if (!lastEvent?.sessionId) return alert('Student is not in a session');
                    /*
                     * BvdS: Turned off July 12, 2023
                     * Was getting cases in testing where this was blocking messages.
                     * BvdS, July 20:  this was likely calculated incorrectly.
                     */
                    if (false && !studentStatus.isWatchAvailable) return alert("Student's watch is busy");
                    if ('classId' in student)
                      setMessageModalState((prev) => {
                        const stu = student as UserWithClassId;
                        const newState = { ...prev, open: true, student: stu };
                        return newState;
                      });
                  }}
                >
                  <MessageIcon />
                </ActionButton>
                <Tooltip anchorSelect={'#' + tipId}>
                  <Text variant="p">Message {student.firstName || 'student'}</Text>
                </Tooltip>
              </>
            )}
          </ActionHeader>
        </>
      )}
      {debug && listify(screencastWindow)}
    </>
  );
};
