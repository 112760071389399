// @ts-strict-ignore
import { createReducer } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';
import {
  updateProfile,
  updateProfileUi,
  signin,
  signout,
  user,
  onLocationChange,
  getState,
  setState,
  userKeys,
  signup,
  resetPasswordStart,
  resetPasswordFinish,
  resetPasswordReset,
  cypressChangeCookieExpiration,
} from '../actions/user';
import { UserKeyResponse, UserAccountProfile, ExternalSource } from '../../../types/routes/user';
import { InstitutionUserRole, UserRole, UserAccountStatus } from '../../../types/models';
import { DashboardState } from '../../api/user';
import { debugAuth } from '../../../settings';

export interface State {
  fetchingUser: boolean;
  fetchedUser: boolean;
  status: UserAccountStatus | null;
  id: undefined | number;
  email: string;
  emails: string[];
  name: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  roles: UserRole[];
  institutionRoles: {
    institutionId: number;
    roles: InstitutionUserRole[];
  }[];
  userFetchLastSuccess: number | null;
  authError: string | null;
  authStyle: AlertColor | null;
  authErrorHttpCode: number | null;
  authErrorName: string | null;
  resetPassword: 'success' | 'failed' | 'resetting' | undefined;
  fetchingUserKeys: boolean;
  externalSource: ExternalSource | null;
  externalInstance: string | null;
  userKeys: UserKeyResponse;
  profile: UserAccountProfile;
  profileRes: string | null;
  profileResStyle: AlertColor | null;
  profileFetching: boolean;
  dashboard: DashboardState | null;
  fetchingDashboard: boolean;
  dashboardLoaded: boolean;
}

const initialState: State = {
  fetchingUser: true,
  fetchedUser: false,
  status: null,
  id: undefined,
  email: '',
  emails: [],
  name: null,
  firstName: null,
  middleName: null,
  lastName: null,
  roles: [],
  institutionRoles: [],
  userFetchLastSuccess: null,
  authError: null,
  authStyle: null,
  authErrorHttpCode: null,
  authErrorName: null,
  resetPassword: undefined,
  fetchingUserKeys: false,
  externalSource: null,
  externalInstance: null,
  userKeys: [],
  profile: {},
  profileRes: null,
  profileResStyle: null,
  profileFetching: false,
  dashboard: null,
  fetchingDashboard: false,
  dashboardLoaded: false,
};

const toAuthIntent = (name: string): AlertColor => {
  return name == 'pending' ? 'success' : 'error';
};

const clearErrorState = (state: State) => {
  state.authError = null;
  state.authStyle = null;
  state.authErrorHttpCode = null;
  state.authErrorName = null;
};

export default createReducer<State>(initialState, (builder) =>
  builder
    .addCase(onLocationChange, (state) => {
      clearErrorState(state);
    })
    .addCase(user.pending, (state) => {
      state.fetchingUser = true;
      state.fetchedUser = false;
      state.id = undefined;
      state.email = '';
      state.emails = [];
      state.name = null;
      state.firstName = null;
      state.middleName = null;
      state.lastName = null;
      state.roles = [];
      state.profile = {};
      state.externalSource = null;
      state.externalInstance = null;
      state.authError = null;
      state.authStyle = null;
      state.authErrorHttpCode = null;
      state.authErrorName = null;
    })
    .addCase(user.fulfilled, (state, action) => {
      state.fetchedUser = true;
      state.fetchingUser = false;
      state.status = UserAccountStatus[action.payload.status];
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.emails = action.payload.emails;
      state.name = action.payload.name;
      state.firstName = action.payload.firstName;
      state.middleName = action.payload.middleName;
      state.lastName = action.payload.lastName;
      state.roles = action.payload.roles.map((x: string) => UserRole[x]);
      state.institutionRoles = action.payload.institutionRoles as any;
      state.externalSource = action.payload.externalSource;
      state.externalInstance = action.payload.externalInstance;
      state.profile = action.payload.profile;
      state.userFetchLastSuccess = Date.now();
    })
    .addCase(user.rejected, (state, action) => {
      state.fetchedUser = false;
      state.fetchingUser = false;
      if (debugAuth) console.log(`*** user rejected`, action.payload);
      if (action.payload) {
        state.authError = action.payload.message;
        state.authStyle = toAuthIntent(action.payload.name);
        state.authErrorHttpCode = action.payload.httpCode;
        state.authErrorName = action.payload.name;
      }
    })
    .addCase(getState.pending, (state) => {
      state.fetchingDashboard = true;
    })
    .addCase(getState.fulfilled, (state, action) => {
      state.fetchingDashboard = false;
      state.dashboardLoaded = true;
      state.dashboard = action.payload;
    })
    .addCase(setState.pending, (state) => {
      state.fetchingDashboard = true;
    })
    .addCase(setState.fulfilled, (state, action) => {
      state.fetchingDashboard = false;
      state.dashboardLoaded = true;
      state.dashboard = action.payload;
    })
    .addCase(userKeys.pending, (state) => {
      state.fetchingUserKeys = true;
      state.userKeys = [];
    })
    .addCase(userKeys.fulfilled, (state, action) => {
      state.fetchingUserKeys = false;
      state.userKeys = action.payload;
    })
    .addCase(signup.pending, (state) => {
      state.fetchingUser = true;
      state.fetchedUser = false;
      state.id = undefined;
      state.email = '';
      state.emails = [];
      state.name = null;
      state.firstName = null;
      state.middleName = null;
      state.lastName = null;
      state.roles = [];
      state.institutionRoles = [];
      state.profile = {};
      state.authError = null;
      state.authStyle = null;
    })
    .addCase(signup.fulfilled, (state, action) => {
      state.fetchedUser = true;
      state.fetchingUser = false;
      state.status = UserAccountStatus[action.payload.status];
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.emails = action.payload.emails;
      state.name = action.payload.name;
      state.firstName = action.payload.firstName;
      state.middleName = action.payload.middleName;
      state.lastName = action.payload.lastName;
      state.roles = action.payload.roles.map((x: string) => UserRole[x]);
      state.institutionRoles = action.payload.institutionRoles as any;
      state.authError = null;
      state.profile = action.payload.profile;
      state.userFetchLastSuccess = Date.now();
    })
    .addCase(signup.rejected, (state, action) => {
      state.fetchedUser = false;
      state.fetchingUser = false;
      if (debugAuth) console.log(`*** signup rejected`, action.payload);
      if (action.payload) {
        state.authError = action.payload.message;
        state.authStyle = toAuthIntent(action.payload.name);
      }
    })
    .addCase(signin.pending, (state) => {
      state.fetchingUser = true;
      state.fetchedUser = false;
      state.id = undefined;
      state.email = '';
      state.emails = [];
      state.name = null;
      state.firstName = null;
      state.middleName = null;
      state.lastName = null;
      state.roles = [];
      state.institutionRoles = [];
      state.authError = null;
      state.authStyle = null;
    })
    .addCase(signin.fulfilled, (state, action) => {
      state.fetchedUser = true;
      state.fetchingUser = false;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.emails = action.payload.emails;
      state.status = UserAccountStatus[action.payload.status];
      state.name = action.payload.name;
      state.firstName = action.payload.firstName;
      state.middleName = action.payload.middleName;
      state.lastName = action.payload.lastName;
      state.roles = action.payload.roles.map((x: string) => UserRole[x]);
      state.institutionRoles = action.payload.institutionRoles as any;
      state.authError = null;
      state.externalSource = action.payload.externalSource;
      state.externalInstance = action.payload.externalInstance;
      state.profile = action.payload.profile;
      state.userFetchLastSuccess = Date.now();
    })
    .addCase(signin.rejected, (state, action) => {
      state.fetchedUser = false;
      state.fetchingUser = false;
      if (debugAuth) console.log(`*** signin rejected`, action.payload);
      if (action.payload) {
        state.authError = action.payload.message;
        state.authStyle = toAuthIntent(action.payload.name);
        state.authErrorHttpCode = action.payload.httpCode;
        state.authErrorName = action.payload.name;
      }
    })
    .addCase(signout.pending, (state) => {
      state.fetchingUser = true;
    })
    .addCase(signout.fulfilled, (state) => {
      state.fetchedUser = false;
      state.fetchingUser = false;
      state.id = undefined;
      state.email = '';
      state.name = null;
      state.firstName = null;
      state.middleName = null;
      state.lastName = null;
      state.roles = [];
      state.institutionRoles = [];
      state.dashboardLoaded = false;
      state.userFetchLastSuccess = null;
    })
    .addCase(resetPasswordStart.pending, (state) => {
      state.resetPassword = 'resetting';
      state.authError = null;
    })
    .addCase(resetPasswordStart.fulfilled, (state) => {
      state.resetPassword = 'success';
      state.authError = null;
    })
    .addCase(resetPasswordStart.rejected, (state, action) => {
      state.resetPassword = 'failed';
      if (debugAuth) console.log(`*** reset password rejected`, action.payload);
      if (action.payload) {
        state.authError = action.payload.message;
        state.authStyle = toAuthIntent(action.payload.name);
      }
    })
    .addCase(resetPasswordFinish.pending, (state) => {
      state.resetPassword = 'resetting';
    })
    .addCase(resetPasswordFinish.fulfilled, (state) => {
      state.resetPassword = 'success';
    })
    .addCase(resetPasswordFinish.rejected, (state) => {
      state.resetPassword = 'failed';
    })
    .addCase(resetPasswordReset, (state) => {
      state.resetPassword = undefined;
    })
    // This is logic to support the rapid-fire updates from typing in the pending account profile form.
    .addCase(updateProfileUi, (state, action) => {
      state.profile = action.payload.profile;
      state.profileRes = 'saving form...';
      state.profileResStyle = 'info';
    })
    .addCase(updateProfile.pending, (state) => {
      state.profileRes = 'saving form...';
      state.profileResStyle = 'info';
      state.profileFetching = true;
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      const match =
        state.profile.profileRole == action.payload.profile.profileRole &&
        state.profile.school == action.payload.profile.school;
      if (match) {
        state.profileRes = 'Changes Saved';
        state.profileResStyle = 'success';
      }
      state.profileFetching = false;
    })
    .addCase(updateProfile.rejected, (state) => {
      state.profile = { school: '', profileRole: '' };
      state.profileRes = 'Could not save form. Please try again or contact Prisms at support@prismsvr.com';
      state.profileResStyle = 'error';
      state.profileFetching = false;
    })
    .addCase(cypressChangeCookieExpiration, (state, action) => {
      console.log('cypressChangeCookieExpiration setting to 10');
      state.userFetchLastSuccess = action.payload;
    }),
);
