// @ts-strict-ignore
import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dictionary } from 'lodash';
import styled from '@emotion/styled';
import { PublicUser, ModuleEventWithUserClass } from '../../../../types/models';
import { sortBy } from 'lodash';
import { Student, finishedPart } from './student';
import { MessageModalState, ClassroomModalState, UserWithClassId, StudentStatus } from '../types';
import { sortOrder, PartialUser } from '../../../utils/user';
import { useSelector } from 'react-redux';
import { getStudentStatuses } from '../event-util';
import { ErrorPrints } from '../types';
import { TaskDefinition } from '../../../../types/routes/module';

interface Props {
  students: UserWithClassId[];
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
  selectedStudent?: number;
  setSelectedStudent: (studentId: number) => void;
  setMessageModalState: (state: MessageModalState) => void;
  setClassroomModalState: (state: ClassroomModalState) => void;
  errorList: ErrorPrints;
}

export const StudentList = ({ students, eventsByStudent, selectedStudent, setSelectedStudent, errorList }: Props) => {
  const moduleVersionDefinitions = useSelector((state) => state.module.versionDefinitions);

  // Find student overrides
  const classIds = new Set(students.map((student) => student.classId));
  const allClassStates = useSelector((state) => state.class.classState);
  const classStates = allClassStates.filter((cs) => classIds.has(cs.classId));
  const classStateLoading = useSelector((state) => state.class.fetchingClassState || state.class.postingClassState);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const debug = query.get('debug') ? true : false;
  const [statusByStudent, setStatusByStudent] = useState<StudentStatus[]>([]);
  const [order, setOrder] = useState<(((student: PublicUser) => number) | ((student: PartialUser) => string))[]>([]);
  const navigate = useNavigate();

  const idleness = (student: PublicUser): boolean => student.id in statusByStudent && statusByStudent[student.id].idle;

  useEffect(() => {
    if (students.length > 0 && !classStateLoading) {
      const statusByStudent = getStudentStatuses(
        students,
        eventsByStudent,
        classStates,
        moduleVersionDefinitions,
        errorList,
        debug ? 0 : null,
      );
      setStatusByStudent(statusByStudent);
    }
  }, [classStateLoading, eventsByStudent]);

  useEffect(() => {
    if (statusByStudent.length > 0) {
      const activeTasksByStudent: { [userId: number]: { task: TaskDefinition; taskIndex: number } } = {};
      // update which tasks each student is actively working on
      students.forEach((s) => {
        if (s.id in statusByStudent) {
          activeTasksByStudent[s.id] = {
            task: statusByStudent[s.id].part?.tasks[statusByStudent[s.id].activeTaskIndex - 1],
            taskIndex: statusByStudent[s.id].activeTaskIndex,
          };
        }
      });
      setOrder([
        (student: PublicUser) =>
          student.id in statusByStudent && statusByStudent[student.id].unreadMessages.length > 0 ? -1 : 1,
        (student: PublicUser) => (idleness(student) ? 1 : 0),
        (student: PublicUser) =>
          idleness(student) ? 0 : (student.id in statusByStudent && statusByStudent[student.id].partIndex) || 0,
        (student: PublicUser) =>
          idleness(student) ? 0 : student.id in statusByStudent && finishedPart(statusByStudent[student.id]) ? 1 : 0,
        (student: PublicUser) =>
          idleness(student) ? 0 : student.id in statusByStudent ? activeTasksByStudent[student.id].taskIndex : 0,
        (student: PublicUser) =>
          activeTasksByStudent[student.id].task &&
          statusByStudent[student.id].finishedTaskSet.has(activeTasksByStudent[student.id].task.id)
            ? 1
            : 0,
        ...sortOrder,
      ]);
    }
  }, [statusByStudent]);

  useEffect(() => {
    const studentId = parseInt(query.get('student'));
    if (studentId && students.some((student) => student.id == studentId)) {
      setSelectedStudent(studentId);
    }
  }, []);

  useEffect(() => {
    if (selectedStudent) query.set('student', `${selectedStudent}`);
    else query.delete('student');
    navigate(
      {
        pathname: window.location.pathname,
        search: query.toString(),
      },
      {
        replace: true,
      },
    );
  }, [selectedStudent]);

  return (
    <Container>
      <StudentsContainer>
        {students &&
          students.every((s) => s.id in statusByStudent) &&
          sortBy(students, order).map((student: UserWithClassId) => (
            <Student
              key={student.id}
              student={student}
              studentStatus={statusByStudent[student.id]}
              selected={selectedStudent === student.id}
              onClick={() => setSelectedStudent(selectedStudent === student.id ? undefined : student.id)}
            />
          ))}
      </StudentsContainer>
    </Container>
  );
};

const Container = styled.div({
  overflowY: 'scroll',
});

const StudentsContainer = styled.div({
  padding: '1rem',
  alignItems: 'left',
});
