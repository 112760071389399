// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Text } from './text';
import { Modal } from './modal';
import { CloseModal, ModalHeader } from './TaskModal';
import { StudentInformation } from './StudentInformation';
import { ActiveRun } from '../pages/liveClassroom/types';
import { Class as ClassWithStudents, Student } from '../../types/routes/class';
import { ModuleEventWithUserClass } from '../../types/models';
import { TaskDefinition } from '../../types/routes/module';

export interface StudentInformationModalData {
  open: boolean;
  currentClass: ClassWithStudents | null;
  student: Student | null;
  studentEvents: ModuleEventWithUserClass[];
  moduleId: string | null;
  modulePart: number | null;
  taskId?: string;
}

export const defaultStudentInformationModalData = {
  open: false,
  currentClass: null,
  student: null,
  studentEvents: [],
  moduleId: null,
  modulePart: null,
};

interface Props {
  studentInformationModalData: StudentInformationModalData;
  setStudentInformationModalData: (studentInformationModalData: StudentInformationModalData) => void;
}

export const StudentInformationModal = ({ studentInformationModalData, setStudentInformationModalData }: Props) => {
  const debugPrint = false;
  const fetchingStudentUsage = useSelector((state) => state.class.fetchingStudentUsage);
  const moduleVersionDefinitions = useSelector((state) => state.module.versionDefinitions);
  const [activeRun, setActiveRun] = useState<ActiveRun | null>(null);
  const [modulePartEvents, setModulePartEvents] = useState<ModuleEventWithUserClass[] | []>([]);
  const [displayTask, setDisplayTask] = useState<TaskDefinition | null>(null);

  useEffect(() => {
    if (debugPrint) console.log(studentInformationModalData);
    const student = studentInformationModalData.student;
    const studentEvents = studentInformationModalData.studentEvents;
    const moduleId = studentInformationModalData.moduleId;
    const modulePart = studentInformationModalData.modulePart;
    const taskId = studentInformationModalData.taskId || null;

    if (student && moduleId && typeof modulePart == 'number' && moduleVersionDefinitions) {
      const lastEvent: ModuleEventWithUserClass =
        studentEvents.findLast((e) => e.moduleId == moduleId && e.taskId) || null;
      if (debugPrint) console.log(lastEvent);
      const version = lastEvent ? lastEvent.version : null;
      const module =
        (version && lastEvent && moduleVersionDefinitions[version]?.modules.find((m) => m.id === lastEvent.moduleId)) ||
        null;
      if (module) {
        const part = module.parts.findLast((p) => p.modulePart === modulePart) || null;
        setDisplayTask(taskId ? part.tasks.findLast((t) => t.id === taskId) : null);
        const modulePartTaskList = part.tasks.map((t) => t.id);
        const modulePartEventsNew: ModuleEventWithUserClass[] = studentEvents.filter(
          (e) => e.moduleId == moduleId && modulePartTaskList.includes(e.taskId),
        );
        if (debugPrint) console.log(modulePartEventsNew);
        if (modulePartEventsNew.length > 0) {
          setModulePartEvents(modulePartEventsNew);
        } else {
          setModulePartEvents([]);
        }
      } else {
        if (debugPrint) console.log("lastEvent's module not found in version defintion");
      }
    } else {
      if (debugPrint) console.log('skipping update');
    }
  }, [studentInformationModalData]);

  const closeModal = () => {
    setStudentInformationModalData(defaultStudentInformationModalData);
  };

  if (studentInformationModalData.currentClass && studentInformationModalData.student && !fetchingStudentUsage) {
    return (
      <Modal isOpen={studentInformationModalData.open ? true : false} closeModal={closeModal} title="">
        <StyledModalHeader>
          {studentInformationModalData.student && <Text variant="md">Progress Details</Text>}
          <CloseModal onClick={closeModal}>&times;</CloseModal>
        </StyledModalHeader>
        <StyledModalContent>
          <StudentInformation
            student={studentInformationModalData.student}
            events={modulePartEvents}
            activeRun={activeRun}
            setActiveRun={setActiveRun}
            hideScrollbar={true}
            forceTimelineTaskSelect={displayTask}
          ></StudentInformation>
        </StyledModalContent>
      </Modal>
    );
  }
};

const StyledModalContent = styled.div({
  width: '80vw',
  height: '80vh',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

const StyledModalHeader = styled(ModalHeader)({
  alignItems: 'center',
  boxShadow: '0 0 20px 5px #fff',
  position: 'relative',
  zIndex: 1,
});
