// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { black, charcoal05, pink, primary } from '../../utils/colors';
import { Text } from '../text';
import Chevron from '../../assets/icons/chevron.svg';

interface Props {
  icon: React.JSX.Element;
  label: string;
  active?: boolean;
  to?: string;
  onClick?: () => void;
}

export const NavLink: React.FC<Props> = ({ to, onClick, icon, label, active }) => {
  return (
    <Container selected={active} to={to || ''} onClick={onClick}>
      <LeftBar active={active} />
      <Left>
        <IconContainer>{icon}</IconContainer>
        <StyledText selected={active} variant="nav">
          {label}
        </StyledText>
      </Left>
      <Chevron />
    </Container>
  );
};

const Container = styled(Link)<{ selected: boolean }>(({ selected }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem 2rem',
  cursor: 'pointer',
  textDecoration: 'none',
  color: selected ? primary : black,
  transition: 'background-color .3s',
  backgroundColor: selected ? pink : charcoal05,

  '&:hover': {
    color: primary,
  },
}));

const LeftBar = styled.div<{ active: boolean }>(({ active }) => ({
  position: 'absolute',
  width: 5,
  backgroundColor: primary,
  top: -2,
  bottom: -2,
  left: 8,
  borderRadius: 2.5,
  opacity: active ? 1 : 0,
  transition: 'opacity .3s',
  zIndex: 1,
}));

const StyledText = styled(Text)<{ selected: boolean }>(() => ({}));

const IconContainer = styled.div({
  marginRight: '.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: primary,
});

const Left = styled.div({
  display: 'flex',
  alignItems: 'center',
});
