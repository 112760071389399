import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Root } from './pages/root';
import { Login } from './pages/login';
import { ClassListPage } from './pages/classListPage';
import { TutorialVideo } from './pages/tutorialVideo';
import { DeviceAccess } from './pages/deviceAccess';
import { ClassDetail } from './pages/classDetail';
import { PrintCards } from './pages/classDetail/printCards';
import { ImportClasses } from './pages/importClasses';
import { AuthRoute } from './components/authRoute';
import { Signup } from './pages/signup';
import { Profile } from './pages/profile';
import { ClassProgressPage } from './pages/classProgress';
import { ClassSummaryPage } from './pages/classSummary';
import { ModuleList } from './pages/moduleList';
import { LivePage } from './pages/liveClassroom';
import { Toolkits } from './pages/toolkits';
import { AccountSettings } from './pages/accountSettings';
import { ResetPasswordEmail } from './pages/resetPasswordEmail';
import { ResetPasswordForm } from './pages/resetPasswordForm';
import { ResetPasswordSuccess } from './pages/resetPasswordSuccess';
import { ResetPasswordStart } from './pages/resetPasswordStart';
import { LtiPlatform } from './pages/ltiPlatform';
import { AccountManagement } from './pages/licenseManagement';
import { ManageInstitution } from './pages/licenseManagement/ManageInstitution';
import { AdminAnalytics } from './pages/adminAnalytics';
import { AdminClassAccess } from './pages/adminClassAccess';
import { RootInstitution } from './pages/adminClassAccess/RootInstitution';
import { useDispatch } from 'react-redux';
import { onLocationChange as onLocationChangeClassAction } from './redux/actions/class';
import { onLocationChange as onLocationChangeUserAction } from './redux/actions/user';

/*
 *  BvdS:  redirect "/" to headset code page for students
 *         'class-list' for teachers and 'accounts' for admins.
 *
 *         Need headset code page as option on left menu.
 */
export const Router = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const debugPrint = false;

  // reset state on location change
  useEffect(() => {
    debugPrint && console.log(location.pathname);
    dispatch(onLocationChangeUserAction());
    dispatch(onLocationChangeClassAction());
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Root />} />
      {/* New loading page: detect login and redirect */}
      <Route path="/admin/analytics" element={<AuthRoute element={<AdminAnalytics />} />} />
      <Route path="/admin/classes" element={<AuthRoute element={<AdminClassAccess />} />} />
      <Route path="/admin/classes/institution/:institutionId" element={<AuthRoute element={<RootInstitution />} />} />
      <Route path="/admin/licenses" element={<AuthRoute element={<AccountManagement />} />} />
      <Route
        path="/admin/licenses/institution/:institutionId"
        element={<AuthRoute element={<ManageInstitution />} />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/class-list" element={<AuthRoute element={<ClassListPage />} />} />
      <Route path="/tutorial-video" element={<AuthRoute element={<TutorialVideo />} />} />
      <Route path="/toolkits" element={<AuthRoute element={<Toolkits />} />} />
      <Route path="/device-access" element={<AuthRoute element={<DeviceAccess />} />} />
      <Route path="/create-class" element={<AuthRoute element={<ClassDetail createMode={true} editMode={true} />} />} />
      <Route
        path="/class/:classId"
        element={<AuthRoute element={<ClassDetail createMode={false} editMode={false} />} />}
      />
      <Route
        path="/class/:classId/edit"
        element={<AuthRoute element={<ClassDetail createMode={false} editMode={true} />} />}
      />
      <Route path="/print-cards/:classId" element={<AuthRoute element={<PrintCards />} />} />
      <Route path="/import-classes" element={<AuthRoute element={<ImportClasses />} />} />
      <Route path="/class/:classId/modules" element={<AuthRoute element={<ModuleList />} />} />
      <Route path="/class/:classId/progress" element={<AuthRoute element={<ClassProgressPage />} />} />
      <Route path="/class/:classId/summary" element={<AuthRoute element={<ClassSummaryPage />} />} />
      <Route path="/class/:classId/live" element={<AuthRoute element={<LivePage />} />} />
      <Route path="/account-settings" element={<AuthRoute element={<AccountSettings />} />} />
      <Route path="/password-reset/start" element={<ResetPasswordStart />} />
      <Route path="/password-reset/email" element={<ResetPasswordEmail />} />
      <Route path="/password-reset/form/:token" element={<ResetPasswordForm />} />
      <Route path="/password-reset/success" element={<ResetPasswordSuccess />} />
      <Route path="/admin/lti-platform" element={<AuthRoute element={<LtiPlatform />} />} />
    </Routes>
  );
};
