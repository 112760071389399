// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { SubjectRowLabel, RowDetails } from '../../components/StyledComponents';
import { Text } from '../../components/text';
import { black, charcoal05, charcoal15, primary } from '../../utils/colors';
import Logo from '../../assets/icons/logo.svg';

import ToolkitIcon from '../../assets/icons/module-toolkit.svg';
import SummaryIcon from '../../assets/icons/summary.svg';
import DashboardIcon from '../../assets/icons/module-dashboard.svg';
import { ModuleDefinition } from '../../../types/routes/module';
import { ModuleLibrary } from '../../redux/reducers/module';
import { getModuleName } from '../../utils/module';
import { moduleImageURL } from '../../../config-public';

export const SubjectRow: React.FC<{
  subjectId: string;
  subjectName: string;
  classId: string;
  modules: ModuleDefinition[];
  childLibrary: ModuleLibrary;
  index: string;
}> = ({ subjectId, subjectName, classId, modules, childLibrary, index }) => {
  const mods = modules.filter((x) => x.subjectId == subjectId);
  const childList = Object.keys(childLibrary);
  // Use localStorage instead of REACT state mechanism
  const statePath = `class/${classId}/subject-state/${subjectId}`;
  const state = JSON.parse(window.localStorage.getItem(statePath)) || {
    open: false,
  };

  const clickHandler = () => {
    state.open = !state.open;
    window.localStorage.setItem(statePath, JSON.stringify(state));
  };

  // BvdS:  Use Library to construct tree of subjects and get subject name.
  return (
    <RowDetails {...(state.open ? { open: true } : {})}>
      <SubjectRowLabel onClick={clickHandler}>{subjectName.toUpperCase()}</SubjectRowLabel>
      <Modules>
        {mods.map((module: ModuleDefinition) =>
          module.parts.map((part) => {
            const modulePart = part.modulePart || 0;
            const tasks = part.tasks;
            const shouldShowSummaryButton = tasks.some((task) => task.skills.length > 0);
            const key = `${index}-${module.id}-${modulePart}`;
            return (
              <Module key={key + '-base'}>
                <ModuleContent to={`/class/${classId}/module/${module.id}/${modulePart}/dashboard`}>
                  {part.image ? (
                    <Image src={moduleImageURL + '/' + module.id + '/' + part.image} />
                  ) : (
                    <ImageContainer>
                      <Logo />
                    </ImageContainer>
                  )}
                  <Title variant="md" center color={black}>
                    {getModuleName(module, modulePart)}
                  </Title>
                </ModuleContent>
                <ButtonBar>
                  {module.alignedResourcesLink && (
                    <StyledButton
                      id={key + '-toolkit'}
                      onClick={() => window.open(module.alignedResourcesLink)}
                      to={`/class/${classId}`}
                    >
                      <StyledToolkitIcon />
                    </StyledButton>
                  )}
                  <Tooltip anchorSelect={'#' + key + '-toolkit'}>
                    <Text variant="nav">Module Toolkit</Text>
                  </Tooltip>
                  <StyledButton
                    id={key + '-dashboard'}
                    to={`/class/${classId}/module/${module.id}/${modulePart}/dashboard`}
                  >
                    <DashboardIcon />
                  </StyledButton>
                  <Tooltip anchorSelect={'#' + key + '-dashboard'}>
                    <Text variant="nav">Module Dashboard</Text>
                  </Tooltip>
                  {shouldShowSummaryButton && (
                    <StyledButton
                      id={key + '-summary'}
                      to={`/class/${classId}/module/${module.id}/${modulePart}/summary`}
                    >
                      <SummaryIcon />
                    </StyledButton>
                  )}
                  <Tooltip anchorSelect={'#' + key + '-summary'}>
                    <Text variant="nav">Summary Report</Text>
                  </Tooltip>
                </ButtonBar>
              </Module>
            );
          }),
        )}
      </Modules>
      {childList.length > 0 &&
        childList.map((subjectId: string, index: number) => (
          <SubjectRow
            subjectId={subjectId}
            subjectName={childLibrary[subjectId].name}
            classId={classId}
            modules={modules}
            childLibrary={childLibrary[subjectId].children}
            key={subjectId + index.toString()}
            index={subjectId + index.toString()}
          />
        ))}
    </RowDetails>
  );
};

const Modules = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '1rem 1rem',
});

const Module = styled.div({
  border: `1px solid ${primary}`,
  borderRadius: 3,
  boxShadow: `0 5px 10px ${charcoal15}`,
  position: 'relative',
  margin: '1rem',
});

const ModuleContent = styled(Link)({
  textDecoration: 'none',
  // The bottom padding gives room for the button bar.
  padding: '1.25rem 1.25rem 6.25rem',
  width: '21.375rem',
  display: 'block',
  cursor: 'pointer',
});

const Image = styled.div<{ src: string }>(({ src }) => ({
  backgroundImage: `url('${src}')`,
  backgroundSize: 'cover',
  backgroundColor: charcoal05,
  height: '12rem',
  marginBottom: '1rem',
}));

const ImageContainer = styled.div({
  backgroundColor: charcoal05,
  height: '12rem',
  marginBottom: '1rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Text)({
  marginBottom: '.25rem',
});

const ButtonBar = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '2rem',
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
});

const StyledButton = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '3rem',
  height: '3rem',
  borderRadius: '3rem',
  border: `1px solid ${primary}`,
  margin: '0 .5rem',
  color: primary,
});

const StyledToolkitIcon = styled(ToolkitIcon)({
  width: '2rem',
  height: '2rem',
});
