// @ts-strict-ignore
import { createReducer } from '@reduxjs/toolkit';
import { LicenseStatus } from '../../../types/models';
import {
  Institution,
  InstitutionLicenseUsage,
  Contract,
  ClassWithTimes,
  ClassWithUsers,
} from '../../../types/routes/accountManagement';
import {
  getRootInstitutionsAction,
  getInstitutionChildrenAction,
  getInstitutionAction,
  getInstitutionClassesWithTimesAction,
  getClassWithTimesAction,
  getInstitutionClassesWithUsersAction,
  getClassContractsAction,
  getInstitutionLicenseUsageAction,
  getInstitutionContractsAction,
} from '../actions/accountManagement';
import { updateLicense } from '../actions/class';

export type LicenseUsage = {
  institutionId: number;
  purchased: number;
  consumed: number;
  reserved: number;
};

export const idWithFilter = (id: number, filter: number[]): string =>
  id.toString() + (filter.length > 0 ? '-' + filter.sort().join(':') : '');

export interface AccountManagementState {
  hasError: boolean;
  errorMessage: string | null;
  fetchingRootInstitutions: boolean;
  rootInstitutions: Institution[];
  institutions: { [id: number]: Institution };
  fetchingInstitution: boolean;
  classesWithTimes: { [id: number]: ClassWithTimes };
  fetchingClassWithTimes: boolean;
  classesWithUsers: { [id: number]: ClassWithUsers };
  institutionContracts: { [institutionId: number]: Contract[] };
  classContracts: { [classId: number]: Contract[] };
  institutionLicenseUsage: { [idWithFilter: string]: InstitutionLicenseUsage };
}

const initialState: AccountManagementState = {
  hasError: false,
  errorMessage: null,
  fetchingRootInstitutions: false,
  rootInstitutions: [],
  institutions: {},
  fetchingInstitution: false,
  classesWithTimes: {},
  fetchingClassWithTimes: false,
  classesWithUsers: {},
  classContracts: {},
  institutionContracts: {},
  institutionLicenseUsage: {},
};

const setErrorState = (state: AccountManagementState, action) => {
  state.hasError = true;
  if (action.payload) {
    state.errorMessage = action.payload.message;
  } else if (action.error && action.error?.message) {
    // Error thrown locally
    console.error(`Redux Error for ${action.type}`, action.error);
    state.errorMessage = action.error.message;
  } else {
    state.errorMessage = 'Unknown error.';
  }
};

export const accountManagementReducer = createReducer<AccountManagementState>(initialState, (builder) =>
  builder
    .addCase(getRootInstitutionsAction.pending, (state) => {
      state.fetchingRootInstitutions = true;
      state.rootInstitutions = initialState.rootInstitutions;
    })
    .addCase(getRootInstitutionsAction.fulfilled, (state, action) => {
      state.rootInstitutions = action.payload.institutions;
      state.fetchingRootInstitutions = false;
    })
    .addCase(getRootInstitutionsAction.rejected, (state, action) => {
      setErrorState(state, action);
      state.fetchingRootInstitutions = false;
    })

    // TODO: Handle the situation where the institution id is invalid.
    .addCase(getInstitutionChildrenAction.pending, (state) => {
      state.fetchingInstitution = true;
    })
    .addCase(getInstitutionChildrenAction.fulfilled, (state, action) => {
      state.fetchingInstitution = false;
      action.payload.institutions.forEach((x) => (state.institutions[x.id] = x));
      state.institutions = { ...state.institutions };
    })
    .addCase(getInstitutionChildrenAction.rejected, (state, action) => {
      state.fetchingInstitution = false;
      setErrorState(state, action);
    })

    .addCase(getInstitutionAction.pending, (state) => {
      state.fetchingInstitution = true;
    })
    .addCase(getInstitutionAction.fulfilled, (state, action) => {
      state.fetchingInstitution = false;
      state.institutions = { ...state.institutions, [action.payload.id]: action.payload };
    })
    .addCase(getInstitutionAction.rejected, (state, action) => {
      state.fetchingInstitution = false;
      setErrorState(state, action);
    })

    .addCase(getInstitutionClassesWithTimesAction.pending, (state) => {
      state.fetchingClassWithTimes = true;
    })
    .addCase(getInstitutionClassesWithTimesAction.fulfilled, (state, action) => {
      state.fetchingClassWithTimes = false;
      action.payload.classes.forEach((x) => (state.classesWithTimes[x.id] = x));
      state.classesWithTimes = { ...state.classesWithTimes };
    })
    .addCase(getInstitutionClassesWithTimesAction.rejected, (state, action) => {
      state.fetchingClassWithTimes = false;
      setErrorState(state, action);
    })

    .addCase(getClassWithTimesAction.pending, (state) => {
      state.fetchingClassWithTimes = true;
    })
    .addCase(getClassWithTimesAction.fulfilled, (state, action) => {
      state.fetchingClassWithTimes = false;
      const x = action.payload;
      state.classesWithTimes[x.id] = x;
      state.classesWithTimes = { ...state.classesWithTimes };
    })
    .addCase(getClassWithTimesAction.rejected, (state, action) => {
      state.fetchingClassWithTimes = false;
      setErrorState(state, action);
    })

    .addCase(getInstitutionClassesWithUsersAction.fulfilled, (state, action) => {
      action.payload.classes.forEach((x) => (state.classesWithUsers[x.id] = x));
      state.classesWithUsers = { ...state.classesWithUsers };
    })
    .addCase(getInstitutionClassesWithUsersAction.rejected, (state, action) => {
      setErrorState(state, action);
    })

    .addCase(updateLicense.fulfilled, (state, action) => {
      if (action.payload.classId in state.classesWithUsers) {
        const c = state.classesWithUsers[action.payload.classId];
        const s = c.students.find((s) => s.id == action.payload.studentId);
        for (const id in state.institutionContracts) {
          for (const c of state.institutionContracts[id]) {
            if (c.id == action.payload.contractId) {
              if (
                action.payload.licenseStatus == LicenseStatus.reserved &&
                s.licenseStatus == LicenseStatus.unreserved
              ) {
                c.reserved++;
              } else if (
                action.payload.licenseStatus == LicenseStatus.unreserved &&
                s.licenseStatus == LicenseStatus.reserved
              ) {
                c.reserved--;
              }
            }
          }
        }
        for (const id in state.institutionLicenseUsage) {
          const x = state.institutionLicenseUsage[id];
          if (x.filter.some((id) => id == action.payload.contractId)) {
            if (action.payload.licenseStatus == LicenseStatus.reserved && s.licenseStatus == LicenseStatus.unreserved) {
              x.licenseUsage.reserved++;
            } else if (
              action.payload.licenseStatus == LicenseStatus.unreserved &&
              s.licenseStatus == LicenseStatus.reserved
            ) {
              x.licenseUsage.reserved--;
            }
          }
        }
        s.licenseStatus = action.payload.licenseStatus;
        // Trigger class update
        state.classesWithUsers[action.payload.classId] = { ...c };
      }
    })

    .addCase(getInstitutionLicenseUsageAction.rejected, (state, action) => {
      setErrorState(state, action);
    })
    .addCase(getInstitutionLicenseUsageAction.fulfilled, (state, action) => {
      const key = idWithFilter(action.payload.institutionId, action.payload.filter);
      state.institutionLicenseUsage = {
        ...state.institutionLicenseUsage,
        [key]: action.payload,
      };
    })

    .addCase(getInstitutionContractsAction.rejected, (state, action) => {
      setErrorState(state, action);
    })
    .addCase(getInstitutionContractsAction.fulfilled, (state, action) => {
      state.institutionContracts = {
        ...state.institutionContracts,
        [action.payload.institutionId]: action.payload.contracts,
      };
    })

    .addCase(getClassContractsAction.rejected, (state, action) => {
      setErrorState(state, action);
    })
    .addCase(getClassContractsAction.fulfilled, (state, action) => {
      state.classContracts = { ...state.classContracts, [action.payload.classId]: action.payload.contracts };
    }),
);
