// @ts-strict-ignore
import { createReducer } from '@reduxjs/toolkit';
import { GetModuleUsageResponse } from '../../../types/routes/module';
import { getModuleUsageAction, resetModuleUsage } from '../actions/adminAnalytics';

export interface State {
  showPopup: boolean;
  hasModuleUsageError: boolean;
  moduleUsageErrorMessage: string | null;
  fetchingModuleUsage: boolean;
  moduleUsage: GetModuleUsageResponse;
}

const moduleUsageEmpty: GetModuleUsageResponse = {
  minTime: 0,
  registeredStudents: 0,
  activeStudents: 0,
  unlicensedStudents: 0,
  modules: [],
  studentTimes: { count: 0, mean: null, median: null, standardDeviation: null },
  studentModuleFractionsCompleted: { count: 0, mean: null, median: null, standardDeviation: null },
  crossModule: [],
  crossModuleBins: -1,
  teachers: [],
};

const initialState: State = {
  showPopup: false,
  hasModuleUsageError: false,
  moduleUsageErrorMessage: null,
  fetchingModuleUsage: false,
  moduleUsage: moduleUsageEmpty,
};

const setModuleUsageErrorState = (state: State, action) => {
  // strict check with false as it might simply be undefined.
  if (action.payload && action.payload.noPopup === false) {
    state.showPopup = true;
  }
  state.hasModuleUsageError = true;
  if (action.payload) {
    state.moduleUsageErrorMessage = action.payload.message;
  } else {
    if (typeof action.error === 'string' && action.error.length > 0) {
      state.moduleUsageErrorMessage = action.error;
    } else {
      state.moduleUsageErrorMessage = 'Unknown error.';
    }
  }
};

export default createReducer<State>(initialState, (builder) =>
  builder
    .addCase(getModuleUsageAction.pending, (state) => {
      state.fetchingModuleUsage = true;
      state.hasModuleUsageError = false;
    })
    .addCase(getModuleUsageAction.fulfilled, (state, action) => {
      state.fetchingModuleUsage = false;
      state.hasModuleUsageError = false;
      state.moduleUsage = action.payload;
    })
    .addCase(getModuleUsageAction.rejected, (state, action) => {
      state.fetchingModuleUsage = false;
      setModuleUsageErrorState(state, action);
    })
    .addCase(resetModuleUsage, (state) => {
      state.fetchingModuleUsage = false;
      state.hasModuleUsageError = false;
      state.moduleUsage = moduleUsageEmpty;
    }),
);
