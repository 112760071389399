// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Text } from '../../components/text';
import { black, charcoal05, darkgray30, primary } from '../../utils/colors';
import Logo from '../../assets/icons/logo.svg';

import { ModuleDefinition } from '../../../types/routes/module';
import { moduleImageURL } from '../../../config-public';
import { useSelector } from 'react-redux';

export const SubjectToolkits: React.FC<{
  subjectModules: string[];
}> = ({ subjectModules }) => {
  const modules = useSelector((state) => state.module.definitions.modules);

  // Modules without a toolkit have already been filtered out
  const leafModules: (ModuleDefinition | undefined)[] = subjectModules.map((moduleId) =>
    modules.find((m) => m.id == moduleId),
  );

  if (leafModules.length > 0)
    return (
      <Modules>
        {leafModules.map(
          (module: ModuleDefinition | undefined) =>
            module && (
              <Module key={module.id} onClick={() => window.open(module.alignedResourcesLink)}>
                <ModuleContent to={`/toolkits`}>
                  {module.image ? (
                    <Image src={moduleImageURL + '/' + module.id + '/' + module.image} />
                  ) : (
                    <ImageContainer>
                      <Logo />
                    </ImageContainer>
                  )}
                  <Title variant="md" center color={black}>
                    {module.name}
                  </Title>
                </ModuleContent>
              </Module>
            ),
        )}
      </Modules>
    );
};

const Modules = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '1rem 1rem',
});

const Module = styled.div({
  border: `1px solid ${primary}`,
  borderRadius: `10px`,
  boxShadow: `0 5px 10px ${darkgray30}`,
  position: 'relative',
  margin: '1rem',
  cursor: 'pointer',
  '&:hover': {
    border: `1px solid ${primary}`,
    boxShadow: `0 2px 10px ${primary}`,
  },
});

const ModuleContent = styled(Link)({
  textDecoration: 'none',
  // The bottom padding gives room for the button bar.
  padding: '1.25rem 1.25rem 1.25rem',
  width: '10rem',
  display: 'block',
});

const Image = styled.div<{ src: string }>(({ src }) => ({
  backgroundImage: `url('${src}')`,
  backgroundSize: 'cover',
  backgroundColor: charcoal05,
  height: '6rem',
  marginBottom: '1rem',
}));

const ImageContainer = styled.div({
  backgroundColor: charcoal05,
  height: '12rem',
  marginBottom: '1rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Text)({
  marginBottom: '.25rem',
});
