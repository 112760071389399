import { Array, Number, Record, Static, String, Boolean, Null, Partial, InstanceOf } from 'runtypes';
import { route } from './utils';

const makeRoutes = () => ({
  getRootInstitutions: route({
    method: 'get',
    path: '/root-institutions',
    requestBody: Record({}),
    responseData: RootInstitutionsRecord,
  }),

  getInstitution: route({
    method: 'get',
    path: '/institution/:institutionId',
    requestBody: Record({}),
    responseData: InstitutionRecord,
  }),
  getInstitutionChildren: route({
    method: 'get',
    path: '/institution/:institutionId/children',
    requestBody: Record({}),
    responseData: RootInstitutionsRecord,
  }),
  getInstitutionClassesWithTimes: route({
    method: 'get',
    path: '/institution/:institutionId/classes-times',
    requestBody: Record({}),
    responseData: ClassesWithTimesRecord,
  }),
  getClassWithTimes: route({
    method: 'get',
    path: '/class/:classId/times',
    requestBody: Record({}),
    responseData: ClassWithTimesRecord,
  }),
  getInstitutionClassesWithUsers: route({
    method: 'get',
    path: '/institution/:institutionId/classes-users',
    requestBody: Record({}),
    responseData: ClassesWithUsersRecord,
  }),
  // List of contracts associated with any ancestor
  // or descendant of an institution, both expired and current
  getInstitutionContracts: route({
    method: 'get',
    path: '/institution/:institutionId/contracts',
    requestBody: Record({}),
    responseData: InstitutionContractsRecord,
  }),
  getInstitutionLicenseUsage: route({
    method: 'get',
    path: '/institution/:institutionId/license-usage',
    requestBody: Record({}),
    responseData: InstitutionLicenseUsageRecord,
  }),

  deleteInstitution: route({
    method: 'delete',
    path: '/institution/:institutionId',
    requestBody: Record({}),
    responseData: Record({}),
  }),

  deleteClass: route({
    method: 'delete',
    path: '/class/:classId',
    requestBody: Record({}),
    responseData: Record({}),
  }),

  deleteUser: route({
    method: 'delete',
    path: '/user/:userId',
    requestBody: Record({}),
    responseData: Record({}),
  }),
});

export const TeacherRecord = Record({
  id: Number,
  name: String.Or(Null),
  firstName: String.Or(Null),
  middleName: String.Or(Null),
  lastName: String.Or(Null),
});

const StudentRecord = TeacherRecord.And(
  Record({
    licenseStatus: String.Or(Null),
    contractId: Number.Or(Null),
  }),
);

export const ClassWithUsersRecord = Record({
  id: Number,
  externalName: String,
  nickName: String.Or(Null),
  section: String.Or(Null),
  active: Boolean,
  students: Array(StudentRecord),
  teachers: Array(TeacherRecord),
  createdAt: InstanceOf(Date),
});

export const ClassWithTimesRecord = Record({
  id: Number,
  externalName: String,
  nickName: String.Or(Null),
  section: String.Or(Null),
  active: Boolean,
  createdAt: InstanceOf(Date),
  startAt: InstanceOf(Date).Or(Null),
  endAt: InstanceOf(Date).Or(Null),
});

export const InstitutionRecord = Record({
  id: Number,
  externalName: String,
  nickName: String.Or(Null),
  level: String.Or(Null),
  children: Array(Number),
  classes: Array(Number),
});

export const TimingRecord = Partial({
  timing: Array(
    Record({
      name: String,
      value: Number,
    }),
  ),
  plans: Array(Record({})),
});

export const RootInstitutionsRecord = Record({
  institutions: Array(InstitutionRecord),
}).And(TimingRecord);

export const ClassesWithTimesRecord = Record({
  classes: Array(ClassWithTimesRecord),
}).And(TimingRecord);

export const ClassesWithUsersRecord = Record({
  classes: Array(ClassWithUsersRecord),
}).And(TimingRecord);

export const ContractRecord = Record({
  id: Number,
  institutionId: Number,
  licensesIssued: Number,
  licenseType: String,
  contractStartDate: InstanceOf(Date),
  contractEndDate: InstanceOf(Date).Or(Null),
  consumed: Number.Or(Null),
  reserved: Number.Or(Null),
  unreserved: Number,
  enforce: Boolean,
});
export const InstitutionContractsRecord = Record({
  institutionId: Number,
  contracts: Array(ContractRecord),
});
export const LicenseUsageRecord = Record({
  consumed: Number,
  reserved: Number,
  unreserved: Number,
  classes: Number,
  students: Number,
  teachers: Number,
  otherContracts: Number,
});
export const InstitutionLicenseUsageRecord = Record({
  institutionId: Number,
  filter: Array(Number).Or(Null),
  licenseUsage: LicenseUsageRecord,
});

export const InstitutionsRequestRecord = Record({});
export const InstitutionsResponseRecord = Array(
  Record({
    id: Number,
    parentId: Number.Or(Null),
    name: String,
    state: String,
    level: String.Or(Null),
    createdAt: InstanceOf(Date),
  }),
);

export type ClassWithUsers = Static<typeof ClassWithUsersRecord>;
export type ClassWithTimes = Static<typeof ClassWithTimesRecord>;
export type Student = Static<typeof StudentRecord>;
export type Teacher = Static<typeof TeacherRecord>;
export type Institution = Static<typeof InstitutionRecord>;
export type Contract = Static<typeof ContractRecord>;
export type LicenseUsage = Static<typeof LicenseUsageRecord>;
export type InstitutionContracts = Static<typeof InstitutionContractsRecord>;
export type InstitutionLicenseUsage = Static<typeof InstitutionLicenseUsageRecord>;
export type InstitutionsResponse = Static<typeof InstitutionsResponseRecord>;
export type InstitutionsRequest = Static<typeof InstitutionsRequestRecord>;
export type RootInstitutions = Static<typeof RootInstitutionsRecord>;
export type ClassesWithTimes = Static<typeof ClassesWithTimesRecord>;
export type ClassesWithUsers = Static<typeof ClassesWithUsersRecord>;

export const accountManagementRoutes = makeRoutes();
