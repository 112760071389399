// @ts-strict-ignore
import React from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import styled from '@emotion/styled';

import { Text } from '../../components/text';
import { Alert } from '../../components/Alert';
import { black, charcoal15, primary, pink } from '../../utils/colors';
import { ModuleUsage } from './ModuleUsage';
import { ModuleLibrary } from '../../redux/reducers/module';
import { LicenseStatusText } from '../../pages/licenseManagement/types';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { sortOrder, formatName } from '../../utils/user';
import { TeacherStatistics } from '../../../types/routes/module';
import { Spinner } from '../../components/Spinner';

// Format for all module usage statistics
const TWO_COLUMNS = true;

export const AllUsage = () => {
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const debug = query.has('debug') ? query.get('debug') : null;
  const moduleUsage = useSelector((state) => state.adminAnalytics.moduleUsage);
  const hasModuleUsageError = useSelector((state) => state.adminAnalytics.hasModuleUsageError);
  const hasAccountManagementError = useSelector((state) => state.accountManagement.hasError);
  const accountManagementErrorMessage = useSelector((state) => state.accountManagement.errorMessage);
  const fetchingModuleUsage = useSelector((state) => state.adminAnalytics.fetchingModuleUsage);
  const fetchingDefinitions = useSelector((state) => state.module.fetchingDefinitions);
  const libraryState = useSelector((state) => state.module.library);

  // remove library leaves without any modules in moduleDefinitions
  const modulesList = moduleUsage.modules.map((x) => x.id);
  function rollupRemoveMissingModules(childrenObj: ModuleLibrary) {
    const returnTree = {};
    Object.keys(childrenObj).forEach((sid: string) => {
      let returnRollup = {};
      const treeNode = childrenObj[sid];
      if (Object.keys(treeNode.children).length > 0) {
        returnRollup = rollupRemoveMissingModules(treeNode.children);
        if (Object.keys(returnRollup).length == 0) {
          return returnTree;
        }
      }
      const validNodeModules = treeNode.modules.filter((x) => modulesList.includes(x));
      if (Object.keys(treeNode.children).length > 0 || validNodeModules.length > 0) {
        returnTree[sid] = { name: treeNode.name, modules: validNodeModules, children: returnRollup };
      }
    });
    return returnTree;
  }
  const library = rollupRemoveMissingModules(libraryState);
  const subjectsFromLibrary: string[] = Object.keys(library);

  if (hasModuleUsageError) return <Alert severity="error" title="Could not fetch module data." />;
  else if (hasAccountManagementError)
    return (
      <Alert severity="error" title="Could not fetch schools or classes.">
        {accountManagementErrorMessage}
      </Alert>
    );
  else if (fetchingModuleUsage || fetchingDefinitions)
    return (
      <div style={{ width: '300px', height: '150px' }}>
        <Spinner label="Fetching data ..." center />
      </div>
    );
  else if (Object.keys(moduleUsage.modules).length == 0)
    return <Alert severity="info" title="Use the filter to select institutions." />;
  else {
    const x2 = TWO_COLUMNS ? 0 : 1;
    const x3 = 1;
    const exampleCount =
      moduleUsage.crossModule.length > x3 && moduleUsage.crossModuleBins > x2 ? moduleUsage.crossModule[x3][x2] : 0;
    const doExample = exampleCount > 1;

    // Order set by Library
    const subjectIds: string[] = moduleUsage ? subjectsFromLibrary : [];

    if (debugPrint) console.log(`**** Subjects ${subjectIds}`);

    // Don't show unlicensed students since that is more
    // properly part of the Account Management page
    return (
      <>
        {debug && <Text variant="nav">unlicensed students: {moduleUsage.unlicensedStudents}</Text>}
        {debug &&
          moduleUsage.timing?.map(({ name, value }: { name: string; value: number }, index) => (
            <Text variant="nav" key={'timing' + index}>
              {name}: {value}
            </Text>
          ))}
        {(moduleUsage.studentTimes.count > 0 || moduleUsage.crossModule.length > 0) && (
          <ModuleUsageContainer>
            <Title variant="md" center>
              Student Usage for all Modules
            </Title>
            <Row>
              <StudentCount variant="p">
                {moduleUsage.registeredStudents} {LicenseStatusText.allPlural}
              </StudentCount>
              <StudentCount variant="p">{moduleUsage.activeStudents} active students</StudentCount>
            </Row>
            {moduleUsage.crossModule.length > 0 && (
              <>
                <ModuleUsageTable>
                  <ModuleUsageTableHeader>
                    <ModuleUsageTableHeaderRow>
                      <ModuleUsageTableHeaderCell>
                        {' '}
                        <ModuleUsageHeaderText variant="p">Module Count </ModuleUsageHeaderText>
                      </ModuleUsageTableHeaderCell>
                      {TWO_COLUMNS ? (
                        <>
                          <ModuleUsageTableHeaderCell key="1">
                            <ModuleUsageHeaderText variant="p">Number started</ModuleUsageHeaderText>
                          </ModuleUsageTableHeaderCell>
                          <ModuleUsageTableHeaderCell key="2">
                            <ModuleUsageHeaderText variant="p">Number completed</ModuleUsageHeaderText>
                          </ModuleUsageTableHeaderCell>
                        </>
                      ) : (
                        moduleUsage.crossModule[0].map((_, i) => (
                          <ModuleUsageTableHeaderCell key={i}>
                            <ModuleUsageHeaderText variant="p">
                              &ge;{((i * 100) / moduleUsage.crossModuleBins).toFixed(0)}%
                            </ModuleUsageHeaderText>
                          </ModuleUsageTableHeaderCell>
                        ))
                      )}
                      <ModuleUsageTableHeaderCell>
                        {' '}
                        <ModuleUsageHeaderText variant="p">
                          Median total time spent
                          <br />
                          in product (minutes)
                        </ModuleUsageHeaderText>
                      </ModuleUsageTableHeaderCell>
                    </ModuleUsageTableHeaderRow>
                  </ModuleUsageTableHeader>
                  <ModuleUsageTableBody>
                    {moduleUsage.crossModule.map((row, index) => (
                      <ModuleUsageTableRow key={index}>
                        <ModuleUsageTableCell>
                          <ModuleNameText variant="md">{index + 1}</ModuleNameText>
                        </ModuleUsageTableCell>
                        {(TWO_COLUMNS ? [row[0], row[row.length - 1]] : row).map((bin, index) => (
                          <ModuleUsageTableCell key={index}>
                            <ModuleUsageDataText variant="md">{bin}</ModuleUsageDataText>
                          </ModuleUsageTableCell>
                        ))}
                        {index == 0 && (
                          <LastModuleUsageTableCell rowSpan={row.length}>
                            {moduleUsage.studentTimes.count > 0 && (
                              <ModuleUsageDataText variant="md">
                                {moduleUsage.studentTimes.count > 0
                                  ? Math.round(moduleUsage.studentTimes.median / 60)
                                  : '-'}
                              </ModuleUsageDataText>
                            )}
                          </LastModuleUsageTableCell>
                        )}
                      </ModuleUsageTableRow>
                    ))}
                  </ModuleUsageTableBody>
                </ModuleUsageTable>
                <ModuleUsageSubtext variant="p">
                  This table shows the extent of student activity. It gives the number of students who have started or
                  completed a given number of modules.{' '}
                  {doExample
                    ? `For example, ${exampleCount} students have ` +
                      (x2 == 0
                        ? `started`
                        : `completed at least ${((x2 * 100) / moduleUsage.crossModuleBins).toFixed(0)}% of`) +
                      ` at least ${x3 + 1} different modules.`
                    : ''}
                </ModuleUsageSubtext>
              </>
            )}
          </ModuleUsageContainer>
        )}
        {moduleUsage.teachers.length > 0 && (
          <ModuleUsageContainer>
            <TitleRowGrid>
              <div></div>
              <TitleRowGridCenter>
                <Title variant="md" center>
                  Per-teacher usage for all Modules
                </Title>
              </TitleRowGridCenter>
              <div></div>
            </TitleRowGrid>
            <MaxTableHeight>
              <ModuleUsageTable>
                <ModuleUsageTableHeader>
                  <ModuleUsageTableHeaderRow>
                    <ModuleUsageTableHeaderCell>
                      {' '}
                      <ModuleUsageHeaderText variant="p">School</ModuleUsageHeaderText>
                    </ModuleUsageTableHeaderCell>
                    <ModuleUsageTableHeaderCell id={`teacher-column-details`}>
                      {' '}
                      <ModuleUsageHeaderText variant="p">Teacher</ModuleUsageHeaderText>
                      <Tooltip anchorSelect={`#teacher-column-details`}>
                        <Text variant="p">{`Only teachers with rostered students are shown.`}</Text>
                      </Tooltip>
                    </ModuleUsageTableHeaderCell>
                    <ModuleUsageTableHeaderCell id={`classes-column-details`}>
                      {' '}
                      <ModuleUsageHeaderText variant="p">Classes</ModuleUsageHeaderText>
                      <Tooltip anchorSelect={`#classes-column-details`}>
                        <Text variant="p">
                          {`Students in multiple classes with a given teacher are only counted once.`}
                        </Text>
                      </Tooltip>
                    </ModuleUsageTableHeaderCell>
                    <ModuleUsageTableHeaderCell id={`students-rostered-column-details`}>
                      {' '}
                      <ModuleUsageHeaderText variant="p">Students Rostered</ModuleUsageHeaderText>
                      <Tooltip anchorSelect={`#students-rostered-column-details`}>
                        <Text variant="p">
                          {`Students imported into our system who have a license covering some
                          portion of the specified time interval`}
                        </Text>
                      </Tooltip>
                    </ModuleUsageTableHeaderCell>
                    <ModuleUsageTableHeaderCell id={`median-task-time-column-details`}>
                      {' '}
                      <ModuleUsageHeaderText variant="p">Median time-on-task (mins)</ModuleUsageHeaderText>
                      <Tooltip anchorSelect={`#median-task-time-column-details`}>
                        <Text variant="p">
                          {`Median time-on-task is calculated for all students who have started a module.  It includes work in all modules.`}
                        </Text>
                      </Tooltip>
                    </ModuleUsageTableHeaderCell>
                    <ModuleUsageTableHeaderCell id={`started-module-column-details1`}>
                      {' '}
                      <ModuleUsageHeaderText variant="p">Started 1 module</ModuleUsageHeaderText>
                    </ModuleUsageTableHeaderCell>
                    <ModuleUsageTableHeaderCell id={`started-module-column-details2`}>
                      {' '}
                      <ModuleUsageHeaderText variant="p">Started 2 modules</ModuleUsageHeaderText>
                    </ModuleUsageTableHeaderCell>
                    <ModuleUsageTableHeaderCell id={`started-module-column-details3`}>
                      {' '}
                      <ModuleUsageHeaderText variant="p">Started 3 modules</ModuleUsageHeaderText>
                    </ModuleUsageTableHeaderCell>
                  </ModuleUsageTableHeaderRow>
                  <Tooltip anchorSelect={`#started-module-column-details1`}>
                    <Text variant="p">{`The number of students who have started at least 1 module`}</Text>
                  </Tooltip>
                  <Tooltip anchorSelect={`#started-module-column-details2`}>
                    <Text variant="p">{`The number of students who have started at least 2 modules`}</Text>
                  </Tooltip>
                  <Tooltip anchorSelect={`#started-module-column-details3`}>
                    <Text variant="p">{`The number of students who have started at least 3 modules`}</Text>
                  </Tooltip>
                </ModuleUsageTableHeader>
                <ModuleUsageTableBody>
                  {sortBy(moduleUsage.teachers, [(a: TeacherStatistics) => a.institutionNames[0], ...sortOrder]).map(
                    (row: TeacherStatistics, index) => (
                      <ModuleUsageTableRow key={index}>
                        <ModuleUsageTableCell>
                          <ModuleNameText variant="md">
                            {row.institutionNames[row.institutionNames.length - 1]}
                          </ModuleNameText>
                        </ModuleUsageTableCell>
                        <ModuleUsageTableCell>
                          <ModuleNameText variant="md">{formatName(row)}</ModuleNameText>
                        </ModuleUsageTableCell>
                        <ModuleUsageTableCell>
                          <ModuleUsageDataText variant="md">{row.classes.length}</ModuleUsageDataText>
                        </ModuleUsageTableCell>
                        <ModuleUsageTableCell>
                          <ModuleUsageDataText variant="md">{row.rosteredStudents}</ModuleUsageDataText>
                        </ModuleUsageTableCell>
                        <ModuleUsageTableCell>
                          <ModuleUsageDataText variant="md">
                            {row.medianTimeOnTask !== null ? Math.round(row.medianTimeOnTask / 60) : '-'}
                          </ModuleUsageDataText>
                        </ModuleUsageTableCell>
                        <ModuleUsageTableCell>
                          <ModuleUsageDataText variant="md">
                            {row.startedHistogram.find((b) => b.bin === 1)?.value ?? 0}
                          </ModuleUsageDataText>
                        </ModuleUsageTableCell>
                        <ModuleUsageTableCell>
                          <ModuleUsageDataText variant="md">
                            {row.startedHistogram.find((b) => b.bin === 2)?.value ?? 0}
                          </ModuleUsageDataText>
                        </ModuleUsageTableCell>
                        <ModuleUsageTableCell>
                          <ModuleUsageDataText variant="md">
                            {row.startedHistogram.find((b) => b.bin === 3)?.value ?? 0}
                          </ModuleUsageDataText>
                        </ModuleUsageTableCell>
                      </ModuleUsageTableRow>
                    ),
                  )}
                </ModuleUsageTableBody>
              </ModuleUsageTable>
            </MaxTableHeight>
          </ModuleUsageContainer>
        )}
        <ModuleUsageContainer data-cy="admin-analytics-all-usage">
          <TitleRowGrid>
            <div></div>
            <TitleRowGridCenter>
              <Title variant="md" center>
                Module Usage
              </Title>
            </TitleRowGridCenter>
          </TitleRowGrid>
          {subjectIds.map((subjectId: string) => (
            <ModuleUsage library={library} moduleUsage={moduleUsage} subjectId={subjectId} key={subjectId} />
          ))}
        </ModuleUsageContainer>
        {debug && (moduleUsage.plans?.length ?? 0) > 0 && (
          <Text variant="nav">
            Use <button onClick={() => window.open('https://explain.dalibo.com')}>https://explain.dalibo.com</button>
          </Text>
        )}
        {debug &&
          moduleUsage.plans?.map((plan: any, index: number) => (
            <Text variant="nav" key={'plan' + index}>
              {JSON.stringify(plan)}
            </Text>
          ))}
      </>
    );
  }
};

const ModuleUsageContainer = styled('div')({
  border: `1px solid ${charcoal15}`,
  borderRadius: 3,
  margin: '1rem',
  paddingBottom: '1rem',
  display: 'block',
  overflowX: 'scroll',
});

const MaxTableHeight = styled('div')({
  maxHeight: '80vh',
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const TitleRowGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
});
const TitleRowGridCenter = styled('div')({
  textAlign: 'center',
});

const Title = styled(Text)({
  color: black,
  fontWeight: 'bold',
  fontSize: '1.25rem',
  marginBottom: '1rem',
  margin: '0 auto',
  padding: '1rem',
});

const StudentCount = styled(Text)({
  color: primary,
  fontWeight: 'bold',
  backgroundColor: pink,
  opacity: 1,
  marginLeft: '0.25rem',
  padding: '0.25rem 0.5rem',
  borderRadius: '0.5rem',
});

const ModuleUsageTable = styled('table')({
  borderCollapse: 'collapse',
  marginBottom: '1rem',
});

const ModuleUsageTableHeader = styled('thead')({
  position: 'sticky',
  top: '0',
  background: 'white',
  boxShadow: '0 7px 7px -4px rgba(0, 0, 0, 0.4)',
});

const ModuleUsageTableHeaderCell = styled('th')({
  padding: '0.5rem 1rem',
  textAlign: 'center',
  borderRight: `1px solid ${charcoal15}`,
  '&:last-child': {
    borderRight: 'none',
  },
});

const ModuleUsageTableHeaderRow = styled('tr')({
  borderBottom: `1px solid ${primary}`,
});

const ModuleUsageTableBody = styled('tbody')({});

const ModuleUsageTableRow = styled('tr')({
  borderBottom: `1px solid ${black}`,
  '&:last-child': {
    borderBottom: 'none',
  },
});

const ModuleUsageTableCell = styled('td')({
  padding: '0.4rem',
  borderRight: `1px solid ${charcoal15}`,
});

const ModuleUsageSubtext = styled(Text)({
  padding: '0 1rem',
});

const LastModuleUsageTableCell = styled('td')({
  padding: '0.4rem',
  borderRight: 'none',
});

const ModuleUsageHeaderText = styled(Text)({
  color: black,
  fontWeight: 500,
  textAlign: 'center',
});

const ModuleNameText = styled(Text)({
  color: primary,
  fontWeight: 400,
  textAlign: 'center',
});

const ModuleUsageDataText = styled(Text)({
  color: black,
  fontWeight: 400,
  textAlign: 'center',
});
