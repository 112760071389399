import { ConfigPublic } from './types/config';

export const server: string = 'app.prismsvr.com';
export const moduleImageURL = 'https://assets.prismsvr.com/module';

export const configPublic: ConfigPublic = {
  clever: {
    oauth2Endpoint: 'https://clever.com/oauth/authorize',
    clientId: 'f15587fa53347013e0d8',
    redirectURL: `https://${server}/api/auth/clever`,
    active: true,
  },
  canvas: {
    clientId: '10000000000002',
    callbackURL: `https://${server}/api/auth/canvas`,
    providerURL: 'https://canvas.prismsvr.com',
    providerName: 'canvas.prismsvr',
    active: false,
  },
  google: {
    clientId: '876057999050-s0traf8ecpds2vecs4vlfocug2i5rp6r.apps.googleusercontent.com',
    redirectURL: `https://${server}/api/auth/google`,
    oauth2Endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
    scopes: [
      'profile',
      'email',
      'https://www.googleapis.com/auth/classroom.rosters.readonly',
      'https://www.googleapis.com/auth/classroom.courses.readonly',
    ],
    active: true,
  },
  gwinnett: {
    clientID: 'BgMd9HJYizhKm3Sxe4ZIodavMaL6NpovkCfh3kPKovIfDPPFiv5SNOGzxMP7',
    callbackURL: `https://${server}/api/auth/ibm-sam`,
    authorizationURL: 'https://apps.gwinnett.k12.ga.us/mga/sps/oauth/oauth20/authorize',
    tokenURL: 'https://apps.gwinnett.k12.ga.us/mga/sps/oauth/oauth20/token',
    jwksURL: 'https://apps.gwinnett.k12.ga.us/mga/sps/oauth/oauth20/jwks/Prisms',
    active: false,
  },
  classLink: {
    // Prisms of Reality - test and - prod use same base URL
    authorizationURL: 'https://launchpad.classlink.com/oauth2/v2/auth',
    // clientId:
    // prod:  'c1663612434116b2f011ce57152782dd3c6062ba9c9c',
    // test:  'c1663612434116c67756ca44c59759c2a42718d9cfb4'
    //
    clientId: 'c1663612434116b2f011ce57152782dd3c6062ba9c9c',
    callbackURL: `https://${server}/api/auth/class-link`,
    tokenURL: 'https://launchpad.classlink.com/oauth2/v2/token',
    active: true,
  },
};
