import { configPublic, server } from './config-public';
import { Config } from './types/config';

export const config: Config = {
  db: {
    user: 'prismsvr',
    password: 'kqOLFudJY1',
    database: 'prismsvr',
    host: 'prisms-prod-db-cluster.cluster-clgwyxr1aoeu.us-east-2.rds.amazonaws.com',
    port: 5432,
    max: 10,
    idleTimeoutMillis: 10000,
  },
  supportEmail: {
    adminServer: 'admin.prismsvr.com',
    staff: 'support@prismsvr.com',
    user: 'no-reply@prismsvr.com',
    clientId: '154036797312-7sotgeiqor7i3pahdu53fvlq0h4oihon.apps.googleusercontent.com',
    clientSecret: 'j3OwE-6mqVI_oMXnsmh1VgAj',
    refreshToken:
      '1//04ksAxQLwCGGiCgYIARAAGAQSNwF-L9Irmokq9dvFQR0uJU1mhaVkUqrp68S7TJpFq_uABZ1scy74Dgz4rsMj49Ni7UzU88AkdEw',
  },
  clever: {
    clientSecret: '0d9244b8ca1cc8e11875c8f0de6842c4ede9c81a',
    ...configPublic.clever,
  },
  canvas: {
    clientSecret: 'h3yPD8IqTgVN3WDCrJdAXP4nl5R1GdwP7xyyy4vwbs1GvC3hKxfTGIbKICl9Vhji',
    ...configPublic.canvas,
  },
  google: {
    clientSecret: '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',
    key: 'AIzaSyCk13omf2aWPnwZ674FtVi5FrscsS5HHHY',
    ...configPublic.google,
  },
  gwinnett: {
    clientSecret: 'PnLjBi3bY0f1l6VD4QkzjHoSeX5mZRKeqnIPkjTIb1PKTS9ne4LZ6Fp6c8zQ',
    ...configPublic.gwinnett,
  },
  classLink: {
    // prod: '0c074a215453f9b9938fb3bac508ff04',
    // test: '5b8ef59a237095622abaf3af466dd873',
    clientSecret: '0c074a215453f9b9938fb3bac508ff04',
    ...configPublic.classLink,
  },
  lti: {
    database: 'lti',
    dynReg: {
      url: `https://${server}/lti`,
      name: 'Prisms Virtual Reality',
      logo: `https://${server}/assets/images/PrismsOfReality_Logo200x200.png`,
      description: 'Learn math using a virtual reality headset',
      redirectUris: [`https://${server}`, `https://${server}/lti/login`, `https://${server}/lti/keys`],
      customParameters: {},
      autoActivate: true,
    },
  },
  firebase: {
    apiKey: 'AIzaSyAcx8icA-HRLRq0cqZU4_iXImSCmWWjHxY',
    authDomain: 'prismsvr-2682a.firebaseapp.com',
    databaseURL: 'https://prismsvr-2682a-default-rtdb.firebaseio.com',
    projectId: 'prismsvr-2682a',
    storageBucket: 'prismsvr-2682a.appspot.com',
    messagingSenderId: '121575330043',
    appId: '1:121575330043:web:8dbf5f5c751d5e09efa05e',
    measurementId: 'G-ZR3LBTXM7P',
  },
  django: {
    apiSecret: 'prisms_secret_django_key',
  },
  notion: {
    // NOTION_API_KEY
    auth: 'secret_5x7yBCMA0gPT1Tb8PPW07EKNNNuJjTqsReCbqwSUxUx',
    database: {
      version: '1bac88d0b264476bb46d5c91c96c78ee',
      module: '202773a3eae7400583530ff7b6567ed6',
      part: 'cf190d7863c44deaa33e8babfc3c6782',
      task: '894c1b99fe1c4e768c077d9fbacab899',
      subject: '1b3a46b233c54e3b9e8c2b852282d8be',
      hint: 'bccc3d1c993d426d89c845a2a4cba898',
      skill: '2e07620abb844afcb87e854eb15ff2d8',
    },
  },
  vitally: {
    restApiKey: 'c2tfbGl2ZV9kZjAzMjY4NGJkMzBkN2NmNzUxZjMzNjc1OTk5YmRiODBkNDJhZTUzZWE0N2IwNDVkNzljMTU1MmRiNWFiZjc4Og==',
    analyticsApiKey:
      'ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmhkV1FpT2lKaFltVm1NVFppWmkxalpqSm1MVFJoTlRrdFltWXpOaTA0Tm1Wa09EYzBObVU0TXpraUxDSmhjR2tpT2lKaVpEZGlNRE01TVMxaVpXUTFMVFJrWXpVdFlqUTVaaTFoTkdVMVpUTmxPV1l5WkdZaUxDSnpaV01pT2lKaU9UZzVZVEJqTmlKOS5Pb2NCN1lYaDNySVd3c1dtbjM0d2EyT1NnbWlJcHV3YUNqTEJWMkRLQkVVOg==',
    subdomain: 'prismsvr',
  },
   httpsPort: 8081,
  httpPort: 8080,
  https: false,
  httpsProxyServer: true,
  liveReload: false,
  sessionSecret: 'prismsvr',
};
