import React, { useState } from 'react';
import { Modal } from './modal';
import styled from '@emotion/styled';
import { TaskDefinition } from '../../types/routes/module';
import { charcoal15, primary } from '../utils/colors';
import { Text } from './text';
import { Button } from './button';
import { CustomTooltip as Tooltip } from './tooltip';
import { TaskState } from '../pages/liveClassroom/types';
import { moduleImageURL } from '../../config-public';
import { TaskModalChecklist } from './TaskModalChecklist';

/*
 * The value for the width was chosen to be
 * about 1/3 of a typical screen width for a tablet.
 */
const taskDescriptionWidth = 400; // width in pixels

interface Props {
  moduleId: string;
  taskIndex: number;
  task: TaskDefinition;
  state?: TaskState;
  onClick?: (task: TaskDefinition) => void;
  active?: boolean;
  wholeClass: boolean;
}

interface TaskContentProps {
  moduleId: string;
  task: TaskDefinition;
  state?: TaskState;
  wholeClass: boolean;
}

export const TimelineTask: React.FC<Props> = ({
  moduleId,
  taskIndex,
  task,
  state,
  onClick,
  active,
  wholeClass,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipId = React.useId();

  const toggleModal = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = isOpen ? '' : 'hidden';
  };

  /*
   * Set the overlay z-level to be greater the menu
   * button z-level (99).
   *
   * If the width is not set explicitly here, the
   * width of the dialog box blows up as you stretch
   * the window.
   */
  return (
    <>
      <Modal isOpen={isOpen} closeModal={toggleModal} title="">
        <ModalHeader>
          <TaskNumber variant="nav">TASK {taskIndex + 1}</TaskNumber>
          <TaskName variant="nav" bold>
            {task.name}
          </TaskName>
          <CloseModal onClick={toggleModal}>&times;</CloseModal>
        </ModalHeader>
        <CenteredModalChildren>
          <TaskContent moduleId={moduleId} task={task} state={state} wholeClass={wholeClass} />
        </CenteredModalChildren>
        <Footer>
          <Button onClick={toggleModal}>Close</Button>
        </Footer>
      </Modal>
      <Container
        id={tooltipId}
        task={task}
        state={state}
        onClick={onClick ? () => (wholeClass ? toggleModal() : onClick(task)) : () => {}}
        active={!!active}
        data-cy={'taskModal-timelineTask-task-' + taskIndex}
        {...rest}
      ></Container>
      <Tooltip anchorSelect={`[id="${tooltipId}"]`} place="top" delayHide={0}>
        <Text variant="p">Click for details</Text>
      </Tooltip>
    </>
  );
};

export const TaskContent: React.FC<TaskContentProps> = ({ moduleId, task, state, wholeClass }) => {
  const processor = document.createElement('div');
  processor.innerHTML = task.description;
  const taskDescription = processor.textContent;

  return (
    <TaskContentContainer data-cy="taskModal-taskContent-container">
      <Text variant="p" center>
        {taskDescription}
      </Text>
      {task.images &&
        task.images.map((image, index) => {
          const path = moduleImageURL + '/' + moduleId + '/' + image;
          // BvdS:  <div> with backgroundImage didn't work in popup
          return <ToolImage key={index} src={path} />;
        })}
      {task.question && task.question.trim() !== taskDescription?.trim() && (
        <>
          <QuestionContainer>
            <Text variant="p" center bold>
              Question
            </Text>
            <Text variant="p" dangerouslySetInnerHTML={{ __html: task.question }} />
          </QuestionContainer>
        </>
      )}
      {task.question && task.answer && (
        <AnswerContainer>
          <Text variant="p" center bold>
            Answer
          </Text>
          <Answer dangerouslySetInnerHTML={{ __html: task.answer.toString() }} />
        </AnswerContainer>
      )}
      {wholeClass && state && state.count > 0 && (
        <TaskProgress>
          <ProgressContainer>
            <Text variant="nav">Started:&nbsp;</Text>
            <Text variant="nav" bold>
              {Math.round((state.started / state.count) * 100)}%
            </Text>
          </ProgressContainer>
          <FinishedContainer>
            <Text variant="nav">Finished:&nbsp;</Text>
            <Text variant="nav" bold>
              {Math.round((state.finished / state.count) * 100)}%
            </Text>
          </FinishedContainer>
        </TaskProgress>
      )}
      {state?.checklist && <TaskModalChecklist checklist={state.checklist} />}
    </TaskContentContainer>
  );
};

export const CloseModal = styled.div({
  fontSize: '1.25rem',
  border: 'solid #a7a7a7',
  padding: '0 0.25rem',
  borderRadius: '.5rem',
});

export const ModalHeader = styled.span({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '0.5rem',
});

export const Footer = styled.span({
  display: 'flex',
  justifyContent: 'end',
});

const Container = styled.div<{ task: TaskDefinition; state?: TaskState; active: boolean }>(
  {
    height: '1.75rem',
    width: '1.75rem',
    backgroundColor: 'white',
    borderRadius: '3rem',
    position: 'relative',
    boxShadow: `0px 3px 5px ${charcoal15}`,
    cursor: 'pointer',
    backgroundSize: 'cover',
    boxSizing: 'border-box',
    transition: 'border-color .3s',
  },
  ({ state, active }) => ({
    backgroundImage: `url('${getIcon(state)}')`,
    border: active ? `2px solid ${primary}` : 0,
  }),
);

export const getIcon = (state?: TaskState): string => {
  if (!state) return `/assets/icons/context-inprogress.png`;
  let iconState = 'incomplete';
  if (2 * state.finished >= state.count && state.count > 0) iconState = 'complete';
  else if (2 * state.started >= state.count && state.count > 0) iconState = 'inprogress';

  return `/assets/icons/context-${iconState}.png`;
};

const CenteredModalChildren = styled.div({
  justifyContent: 'center',
  margin: 'auto',
  width: taskDescriptionWidth + 32,
});

const TaskContentContainer = styled.div({
  width: taskDescriptionWidth,
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
});

export const TaskNumber = styled(Text)({
  marginBottom: '.5rem',
  alignSelf: 'center',
});

export const TaskName = styled(Text)({
  marginBottom: '.5rem',
  alignSelf: 'center',
});

const TaskProgress = styled.div({
  margin: '1rem 0',
});

const FinishedContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ProgressContainer = styled(FinishedContainer)({
  marginBottom: '.5rem',
});

const QuestionContainer = styled.div({
  marginTop: '.5rem',
  marginBottom: '.5rem',
});

const AnswerContainer = styled.div({});

const ToolImage = styled.img({
  marginTop: '0.3rem',
  marginBottom: '0.3rem',
  maxWidth: '400px',
  width: '100%',
});

const Answer = styled.div({
  // The text style should inherit from <Text variant="p" center>
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 300,
  fontSize: '.875rem',
  textAlign: 'center',
  //
  marginTop: '.5rem',
  marginBottom: '.5rem',
  table: {
    // center the table horizontally
    margin: '0 auto',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    textAlign: 'center',
    tbody: {
      tr: {
        td: {
          padding: '0.25rem 0.5rem 0',
        },
        '&:first-of-type': {
          td: {
            padding: '0.25rem 0.5rem 0.25rem',
            borderBottom: `1px solid ${primary}`,
          },
          th: {
            padding: '0.25rem 0.5rem 0.25rem',
            borderBottom: `1px solid ${primary}`,
          },
        },
        '&:last-child': {
          td: {
            padding: '0 0.5rem 0.25rem',
            borderBottom: `1px solid ${primary}`,
          },
        },
      },
    },
  },
});
