// @ts-strict-ignore
import { ModuleDefinition } from '../../../types/routes/module';
import { ModuleEventWithUserClass } from '../../../types/models';
import { useSelector } from 'react-redux';
import { Dictionary, map } from 'lodash';
import React from 'react';
import { SubjectLeafProps } from '../../components/SubjectTree';
import { ModuleSummaryTable } from './moduleSummaryTable';
import { RowDetails, SubjectRowLabel } from '../../components/StyledComponents';

export interface SummaryReportLeafData {
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
  lastEventByStudent: Dictionary<ModuleEventWithUserClass>;
}

export const SubjectSummaryReport = ({
  subjectModules,
  currentClass,
  leafData,
}: SubjectLeafProps<SummaryReportLeafData>) => {
  const modules = useSelector((state) => state.module.definitions.modules);
  const leafModules: ModuleDefinition[] = modules.filter((module) => subjectModules.indexOf(module.id) != -1);

  if (leafModules.length > 0)
    return (
      <>
        {map(leafModules, (module) => {
          const eventsByStudentAndModule = {};
          Object.keys(leafData.eventsByStudent).forEach((k) => {
            eventsByStudentAndModule[k] = leafData.eventsByStudent[k].filter((e) => e.moduleId == module.id);
          });
          return (
            <RowDetails key={module.id}>
              <SubjectRowLabel>{module.name.toUpperCase()}</SubjectRowLabel>
              <ModuleSummaryTable
                currentClass={currentClass}
                module={module}
                eventsByStudentAndModule={leafData.eventsByStudent}
              />
            </RowDetails>
          );
        })}
      </>
    );
};
