// @ts-strict-ignore
import { ClassWithUsers as Class, Contract } from '../../../types/routes/accountManagement';
import { LicenseStatusText } from './types';

export type TallyTotals = {
  children: number;
  teacherIds: Set<number>;
  classes: number;
  studentIds: Set<number>;
};

export type TeacherClasses = {
  id: number;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  classes: Class[];
};

export const newTally = (): TallyTotals => ({
  children: 0,
  teacherIds: new Set(),
  classes: 0,
  studentIds: new Set(),
});

export function tallyClass(thing: Class, totals: TallyTotals = newTally()): TallyTotals {
  thing.teachers.forEach((t) => totals.teacherIds.add(t.id));
  thing.students.forEach((s) => totals.studentIds.add(s.id));
  return totals;
}

// Define "licenses used" as consumed + reserved
// Should be 0 or 1
export const INCLUDE_RESERVED = 0;

export const usedText = (used: number): string =>
  used > 1
    ? INCLUDE_RESERVED
      ? LicenseStatusText.usedPlural
      : LicenseStatusText.consumedPlural
    : INCLUDE_RESERVED
    ? LicenseStatusText.used
    : LicenseStatusText.consumed;

/*
 * Count licenses that are currently available for use/consumption
 * for the selected contracts.
 */
export const countLicensesLeft = (contracts: Contract[] | undefined): number | null => {
  const now = new Date();
  return contracts
    ? contracts.reduce(
        (x, y) =>
          y.contractStartDate < now && (!y.contractEndDate || y.contractEndDate > now)
            ? x + y.licensesIssued - INCLUDE_RESERVED * y.reserved - y.consumed
            : x,
        0,
      )
    : null;
};
