export enum ConfigName {
  noDirectLoginEmail = 'noDirectLoginEmail',
}

export interface Config {
  id: number;
  name: ConfigName;
  value: any | null;
  createdAt: Date;
}

export enum NotificationType {
  internalServerError = 'internalServerError',
  tokenError = 'tokenError',
  userImportError = 'userImportError',
  classImportError = 'classImportError',
  institutionImportError = 'institutionImportError',
  classListImportError = 'classListImportError',
  clientError = 'clientError',
  // User authentication has a non-fatal unexpected format
  userImportWarning = 'userImportWarning',
  // The class has a non-fatal unexpected format
  classImportWarning = 'classImportWarning',
  // Action forbidden for given login.
  forbiddenError = 'forbiddenError',
  loginWarning = 'loginWarning',
  licenseWarning = 'licenseWarning',
  // Insitution/contract tree not set up properly
  institutionError = 'institutionError',
  // BvdS: This is special to disabling license enforcement
  licenseEnforcementWarning = 'licenseEnforcementWarning',
  logDataWarning = 'logDataWarning',
  sendMessageError = 'sendMessageError',
  failedToSendEmail = 'failedToSendEmail',
}

export enum NotificationStatus {
  // Known issue, customer support needs to contact customer
  // Licensing is included here
  customerSupport = 'customerSupport',
  // Unknown issue, needs to be investigated
  // Generally, HTTP 5xx errors should be marked this way
  investigate = 'investigate',
  // Issue does not need immediate attention
  minor = 'minor',
  // Issue has been addressed
  // For instance, the bug has been fixed, or customer contacted
  fixed = 'fixed',
}

export interface Notification {
  id: number;
  notificationType: NotificationType;
  message: string | null;
  userId: number | null;
  classId: number | null;
  externalSource: ExternalSource | null;
  status: NotificationStatus;
  // JSON in the database
  profile: object;
  instances: object[];
  times: Date[];
  updatedAt: Date;
}

export interface NewNotification {
  notificationType: NotificationType;
  message?: string | null;
  userId?: number | null;
  classId?: number | null;
  externalSource?: ExternalSource | null;
  status?: NotificationStatus;
  // JSON in the database
  profile: object;
  instance?: object;
}

export interface User {
  id: number;
  status: UserAccountStatus;
  name: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string;
  password: string;
  createdAt: Date;
  profile: unknown | null;
}

export interface UserState {
  classId: number | null;
  userId: number | null;
  name: string;
  value: any | undefined;
  createdAt: Date;
}

// This should match UserAccountStatus in types/routes/user.ts
export enum UserAccountStatus {
  // user.status in the db
  active = 'active', // can currently log in
  disabled = 'disabled', // account has been made inactive or was rejected
  pending = 'pending', // account created and under review has not been made active
}

export enum UserStateName {
  learnerType = 'learnerType',
  pauseNow = 'pauseNow',
  pauseAtTasks = 'pauseAtTasks',
  launchIntoModule = 'launchIntoModule',
  language = 'language',
  accessibility = 'accessibility',
  updateObjectNow = 'updateObjectNow',
  helpGiving = 'helpGiving',
  screencast = 'screencast',
  pendingEmailSent = 'pendingEmailSent',
}

export enum InstitutionLevel {
  county = 'county',
  district = 'district',
  school = 'school',
  // Unknown is for google classroom imports since nature of institution is unknown.
  unknown = 'unknown',
  /*
   *  In Clever, the student grouping is a "section" which
   *  maps onto our "class."
   *  Thus, a Clever "class" is a grouping of our classes.
   */
  class = 'class',
}

/*
 * The view institution_and_descendants creates
 * the state value "_unknown".  However, this should
 * never be accessed in a query (or set).
 */
export enum InstitutionState {
  default = 'default', // Normal schools
  'ersatz' = 'ersatz', // For testing only
  internal = 'internal', // Prisms internal, training
  consumer = 'consumer', // Consumer product
}

// Following the format used by Clever for schools
export interface InstitutionLocation {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface Institution {
  id: number;
  parentId: number | null;
  level: InstitutionLevel | null;
  externalName: string;
  nickName: string | null;
  state: InstitutionState;
  createdAt: Date;
  // Set to {} if omitted
  location?: Partial<InstitutionLocation>;
}

export interface InstitutionWithChildren extends Institution {
  children: number[];
  classes: number[];
}

export interface InstitutionExternal {
  id: number;
  institutionId: number;
  externalSource: ExternalSource;
  externalId: string;
  externalInstance: string;
  createdAt: Date;
  // The profile object is sent from the external source, format unknown.
  profile?: unknown | null;
}

export interface InstitutionAnalysis {
  institutionId: number | null;
  userId: number | null;
  // In the database, this is a "date" type
  // There is no Javascript analog, so we use a string
  start: string;
  interval: number; // Number of days
  name: string;
  value: any | undefined;
  createdAt: Date;
  profile: unknown | null;
}

export interface ClassUserAnalysis {
  classId: number | null;
  userId: number | null;
  // In the database, this is a "date" type
  // There is no Javascript analog, so we use a string
  start: string;
  interval: number; // Number of days
  name: string;
  value: any | undefined;
  createdAt: Date;
  profile: unknown | null;
}

export interface UserModuleAnalysis {
  classId: number | null;
  userId: number | null;
  moduleId: string;
  taskId: string;
  name: string;
  value: any | undefined;
  createdAt: Date;
  modifiedAt: Date;
}

export interface Contract {
  id: number;
  institutionId: number;
  contractStartDate: Date;
  contractEndDate: Date | null;
  priority: number;
  licensesIssued: number;
  licenseType: string;
  createdAt: Date;
  deletedAt: Date | null;
  reserved: number | null;
  consumed: number | null;
  enforce: boolean;
}

export interface ContractUser {
  id: number;
  contractId: number;
  userId: number;
  licenseStatus: number;
  createdAt: Date;
}

export interface ContractWithUnreserved extends Contract {
  unreserved: number;
}

export interface License {
  id: number;
  contractId: number;
  institutionId: number;
  userId: number;
  createdAt: Date;
  committedAt: Date | null;
}

export interface UserCreate {
  name?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  email: string;
  password?: string;
  status?: UserAccountStatus;
  profile?: unknown | null;
}

export type PublicUser = Omit<User, 'password'>;

export interface UserWithKey extends PublicUser {
  key: string;
}

export interface UserWithLicense extends UserWithKey {
  licenseStatus: LicenseStatus | null;
  contractId: number | null;
}

// This should match ExternalSource defined in types/routes/user.ts
export enum ExternalSource {
  clever = 'clever',
  moodle = 'moodle',
  canvas = 'canvas',
  google = 'google',
  oneRoster = 'oneRoster',
  lti = 'lti',
  // see https://www.notion.so/prismsvr/Platform-Features-fbbde9c2f3604ba587053b18216abd58#fca08d31a3cb4eb583fb74ed289c9d66
  Pico = 'Pico',
  Quest = 'Quest',
  Unknown = 'Unknown',
  Mailer = 'Mailer',
}

export enum OneRosterInstance {
  gwinnett = 'gwinnett',
  classLink = 'classLink',
  infiniteCampus = 'infiniteCampus',
}

export enum ClassUserRole {
  teacher = 'teacher',
  student = 'student',
  removed = 'removed',
}

export enum LicenseStatus {
  consumed = 'consumed',
  reserved = 'reserved',
  unreserved = 'unreserved',
}

export enum LicenseType {
  default = 'default',
  math = 'math',
  science = 'science',
}

// This should match UserRoleRecord in types/routes/user.ts
export enum UserRole {
  teacher = 'teacher',

  /**
   * This is effectively an administrative superuser,
   * who has access to change system-wide configuration
   * such as LTI integration.
   */
  admin = 'admin',
}

export enum InstitutionUserRole {
  /*
   * The administrator of a school/district/etc.
   * In Clever, the corresponding roles are "district_admin"
   * and "school_admin".  Clever also defines the roles "contact" and
   * "staff".  The role "staff" may perhaps be deprecated?
   *
   * On the Teacher Dashboard, this gives access to the Admin Analytics
   * and License Management pages.
   */
  institutionAdmin = 'institutionAdmin',
  /*
   * Allows user to create/edit local classes associated with
   * the institution
   */
  teacher = 'teacher',
}

export enum GetBy {
  class = 'id',
  institution = 'institutionId',
  classroom = 'classroomId',
  institutionAndDescendants = 'institution-and-descendants',
}

interface BaseClass {
  id: number;
  externalName: string;
  nickName: string | null;
  section: string | null;
  institutionId: number | null;
  classroomId: number | null;
  updatedAt: Date | null;
  createdAt: Date;
}

export interface ClassExternal {
  externalSource: ExternalSource | null;
  externalId: string | null;
  externalInstance: string | null;
}

export type PublicClass = BaseClass & ClassExternal;

export type Class = BaseClass &
  ClassExternal & {
    active: boolean;
    profile: unknown | null;
  };

export type ClassWithTimes = Class & {
  startAt: Date | null;
  endAt: Date | null;
};

export interface Classroom {
  id: number;
}

export interface ModuleEvent extends UserStatus {
  id: number;
  action: string;
  createdAt: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: { [key: string]: any };
}

export interface ModuleEventWithUserClass extends ModuleEvent {
  userId: number;
  classId: number;
  version: string | null;
}

export interface ModuleTaskCount {
  moduleId: string;
  taskId: string;
  action: string;
  count: number;
}

export interface UserExternal {
  id: number;
  userId: number;
  externalSource: ExternalSource;
  externalId: string;
  externalInstance: string;
  token: string | null;
  refreshToken: string | null;
  // The profile object is sent from the external source, format unknown.
  profile: unknown | null;
  // Whether the user has ever logged in
  loggedIn: boolean;
  createdAt: Date;
}

export interface UserExternalData {
  source: ExternalSource;
  id: string;
  instance: string;
  token?: string | null;
  refreshToken?: string | null;
  // The profile object is sent from the external source, format unknown.
  profile?: object | null;
}

export interface UserExternalCreate extends UserExternalData {
  userId: number;
}

export interface UserEmail {
  id: number;
  userId: number;
  email: string;
  verified: boolean | null;
  userExternalId: number | null;
  createdAt: Date;
}

export interface UserStatus {
  sessionId: string;
  runId: string;
  moduleId: string;
  taskId: string;
}

export interface Session {
  id: number;
  userId: number;
  classId: number;
  sessionId: string;
  loginEventId: number;
  nextLoginEventId: number | null;
  version: string | null;
}

export interface OneRosterCredentials {
  id: number;
  externalInstance: string;
  externalId: string;
  name: string | null;
  baseURL: string | null;
  tokenURL: string | null;
  clientId: string | null;
  clientSecret: string | null;
  active: boolean;
  createdAt: Date;
}
