// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'lodash';
import styled from '@emotion/styled';

import { Header } from '../../components/header';
import { LeftNav } from '../../components/leftNav';
import { PageContainer } from '../../components/pageContainer';
import { Alert, ExtraAlertText } from '../../components/Alert';
import { Text } from '../../components/text';
import { ActionBar } from '../../components/styles';
import { primary, gray, charcoal15 } from '../../utils/colors';

import { InstitutionRow } from './InstitutionRow';
import { ClassRow } from './ClassRow';
import { displayName, institutionSortOrder } from '../../utils/institution';
import { classSortOrder } from '../../utils/class';
import {
  getInstitutionAction,
  getInstitutionChildrenAction,
  getInstitutionClassesWithUsersAction,
} from '../../redux/actions/accountManagement';
import { useParams } from 'react-router-dom';
// BvdS:  move this to components
import { DateFormatHelp, StyledInput } from '../adminAnalytics/Filter/FilterSelector';
import { makeFilter } from '../../api/adminAnalytics';
import { ClassWithUsers } from '../../../types/routes/accountManagement';
import { Button } from '../../components/button';
import { config } from '../../../config';
import { Spinner } from '../../components/Spinner';

export interface Filter {
  start?: Date | null;
  end?: Date | null;
}

export const classFilterLogic = (c: ClassWithUsers, filter: Filter) => {
  const startFilter = filter.start ? c.createdAt > filter.start : true;
  const endFilter = filter.end ? c.createdAt < filter.end : true;
  return startFilter && endFilter;
};

export const RootInstitution: React.FC = () => {
  const params = useParams();
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const timing = query.get('timing') ? true : false;
  const dispatch = useDispatch();
  // TODO: Do something if districtId is NaN.
  const institutionId = parseInt(params.institutionId);
  const institution = useSelector((s) => s.accountManagement.institutions[institutionId]);
  const institutions = useSelector((state) => state.accountManagement.institutions);
  const children = institution ? institution.children.map((id) => institutions[id]).filter((x) => x) : [];
  children.sort(institutionSortOrder);
  const allClasses = useSelector((state) => state.accountManagement.classesWithUsers);
  const classes = institution ? institution.classes.map((id) => allClasses[id]).filter((x) => x) : [];
  classes.sort(classSortOrder);

  const hasError = useSelector((state) => state.accountManagement.hasError);
  const errorMessage = useSelector((state) => state.accountManagement.errorMessage);

  const [filter, setFilter] = useState<Filter>(pick(makeFilter(), ['start', 'end']));

  const [startInvalid, setStartInvalid] = useState(false);
  const [endInvalid, setEndInvalid] = useState(false);

  // BvdS:  copied from adminAnalytics/Filter/FilterSelector
  const dateHandler = (eventValue: string, type: 'start' | 'end'): void => {
    const d = eventValue.trim() == '' ? null : new Date(eventValue.trim());
    if (!d || !isNaN(d.getTime())) {
      setFilter({ ...filter, [type]: d });
      if (debugPrint) console.log(`---- update filters start ${filter.start} end ${filter.end}`);
    } else {
      if (type == 'start') setStartInvalid(true);
      else if ((type = 'end')) setEndInvalid(true);
    }
    if (debugPrint) console.log(`+++ ${type} value ${d}`);
  };

  useEffect(() => {
    if (!institution) {
      if (debugPrint) console.log(`--- dispatch root institution ${institutionId}`);
      dispatch(getInstitutionAction(institutionId));
    } else {
      for (const childId of institution.children)
        if (!(childId in institutions)) {
          if (debugPrint) console.log(`--- dispatch child ${childId}`);
          dispatch(getInstitutionChildrenAction({ id: institutionId, timing }));
          break;
        }
      for (const c of institution.classes)
        if (!(c in allClasses)) {
          if (debugPrint) console.log(`--- dispatch class ${c}`);
          dispatch(getInstitutionClassesWithUsersAction({ id: institutionId, timing }));
          break;
        }
    }
  }, [institution]);

  if (institution) {
    return (
      <PageContainer header={<Header />} leftNav={<LeftNav />}>
        <ActionBar>
          <Text variant="lg2" color={primary}>
            {displayName(institution)}
          </Text>
        </ActionBar>
        <FilterContainer>
          <Text variant="md">Filter by class creation date</Text>
          <Text variant="p">Start:</Text>
          {startInvalid && <DateFormatHelp />}
          <StyledInput
            defaultValue={filter.start?.toISOString().substring(0, 10) || ''}
            onFocus={() => setStartInvalid(false)}
            onBlur={(e) => dateHandler(e.target.value, 'start')}
          ></StyledInput>

          <Text variant="p">End:</Text>
          {endInvalid && <DateFormatHelp />}
          <StyledInput
            defaultValue={filter.end?.toISOString().substring(0, 10) || ''}
            onFocus={() => setEndInvalid(false)}
            onBlur={(e) => dateHandler(e.target.value, 'end')}
          ></StyledInput>
          <Button onClick={() => {}}>Filter</Button>
        </FilterContainer>
        {hasError && (
          <Alert severity="error" title="Could not fetch schools, classes, or licenses">
            {'Module data could not be retrieved.  This could be due to a loss of network connection.  Please send a screenshot to ' +
              config.supportEmail.staff +
              '.'}
            {errorMessage && <ExtraAlertText>Error message: {errorMessage}</ExtraAlertText>}
          </Alert>
        )}
        {children.map((child) => (
          <InstitutionRow institution={child} key={`child-${child.id}`} filter={filter} />
        ))}
        {institution && institution.children.length > children.length && (
          <Text variant="nav" center color={gray}>
            Getting institution &hellip;
          </Text>
        )}
        <DirectDescendantUl>
          {classes
            .filter((c) => classFilterLogic(c, filter))
            .map((theClass) => (
              <ClassRow classItem={theClass} key={`class-${theClass.id}`} />
            ))}
        </DirectDescendantUl>
      </PageContainer>
    );
  } else {
    return <Spinner label="Getting institution ..." center />;
  }
};

// BvdS:  borrowed from elsewhere.  Make this a standard style?
const FilterContainer = styled('div')({
  border: `1px solid ${charcoal15}`,
  borderRadius: 3,
  margin: '1rem',
  padding: '1rem',
  display: 'inline-block',
});

const DirectDescendantUl = styled.ul({
  margin: '1rem',
  padding: '2rem',
  color: primary,
});
